import { t } from "@lingui/macro";
import { Text, View } from "@react-pdf/renderer";
import { myI18n } from "translation/I18nConnectedProvider";
import { pdfTableStyles } from "app/views/forms/pdf-components/PDFCommon";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from "app/views/forms/pdf-components/FormPdf";

interface FormContactPickerFillablePDFProps {
  value?: object | string | undefined;
  elementType: string;
  langVersion: string;
  title?: string | undefined;
  helpText?: string | undefined;
  tooltip?: string | undefined;
  altLabelPlacement?: string | undefined;
  typeProps?: object | undefined;
}

/**
 * A React component that renders a fillable PDF version of contact picker form element 
 * with contact information fields.
 * It displays a table with columns for first name, last name, job title, email, 
 * and phone number.
 * 
 * @component
 * 
 * @param {Object | string | undefined} props.value - The value to populate the form fields with (optional).
 * @param {string} props.elementType - A string identifier for the element type.
 * @param {string} props.langVersion - The language version to use for translations.
 * @param {string} [props.title] - The optional title of the form element.
 * @param {string} [props.helpText] - Optional help text that explains how to fill the field.
 * @param {string} [props.tooltip] - Optional tooltip text.
 * @param {string} [props.altLabelPlacement] - Optional placement for the label.
 * @param {Object} [props.typeProps] - Optional additional properties to customize the form element behavior.
 * 
 * @returns {JSX.Element} A `View` component representing the form element in a fillable 
 * PDF layout.
 */
export const FormContactPickerFillablePDF: React.FC<FormContactPickerFillablePDFProps> = ({
  value,
  elementType,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  typeProps,
  ...props
}) => {
  const columnStyles = [
    pdfTableStyles.tableCol,
    { width: "20%", minHeight: 36 },
  ];

  const labelStyles = {
    display: "flex" as const,
    alignItems: "flex-start" as const,
    justifyContent: "center" as const,
    fontSize: 10 as const,
    fontWeight: "bold" as const,
    margin: "2px" as const,
    paddingTop: 8 as const,
    paddingBottom: 8 as const,
    fontFamily: langVersion === 'iu-cans' ?
      inuktitutFormPdfFontFamily :
      defaultFormPdfFontFamily,
  };

  return (
    <View style={pdfTableStyles.table}>
      <View style={pdfTableStyles.tableRow}>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`First Name`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`Last Name`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`Job title`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`E-mail`)}</Text>
          </View>
        </View>
        <View style={columnStyles}>
          <View style={labelStyles}>
            <Text>{myI18n._(t`Phone`)}</Text>
          </View>
        </View>
      </View>
      <View style={pdfTableStyles.tableRow}>
        <View style={columnStyles} />
        <View style={columnStyles} />
        <View style={columnStyles} />
        <View style={columnStyles} />
        <View style={columnStyles} />
      </View>
    </View>
  );
};
