import { t, Trans } from '@lingui/macro'
import { getFormPage } from 'app/services/sfAuth/sfData/sfForms'
import { validateYupSchema } from 'formik'
import _ from 'lodash'
import rehypeStringify from 'rehype-stringify'
import remarkParse from 'remark-parse/lib'
import remarkRehype from 'remark-rehype'
import { myI18n } from 'translation/I18nConnectedProvider'
import { unified } from 'unified'
import * as Yup from 'yup'
import { parseFormLabelText } from './common/Common'
import { formComponentTypes } from './components/formComponentTypes'
import { handleRulesFormNumericInputValidation } from './components/numeric-input/formNumericInputValidation'
import { initialTouchedFormElements } from './editor/GroupElement'
import { mapFormElements } from './Form'
import { getDisabledIds } from './FormHelpersConditions'
import { getInitialValues } from './FormHelpersFormik'
import { connectedObjectQuery } from './FormsHelpersQueries'
import {
  emailTrans,
  questionRequiredTrans,
  requiredSite
} from './formTranslations'

export const correctableErrors = {
  ZIP_CODE: {
    text: <Trans>Provided zip code has incorrect format!</Trans>,
    logic: (error) => error.includes('X1X 1X1')
  },
  DUPLICATE_NAME: {
    text: <Trans>Organization with this name already exists!</Trans>,
    logic: (error) =>
      error.includes('Organization with this name already exists!')
  },
  REQUIRED_NAME: {
    text: <Trans>Empty name is invalid!</Trans>,
    logic: (error) => error.includes('[Name]')
  },
  AFFILIATION_ALREADY_EXISTS: {
    text: <Trans>AFFILIATION_ALREADY_EXISTS</Trans>,
    logic: (error) => error.includes('AFFILIATION_ALREADY_EXISTS')
  },
  BUDGET_LINE_DUPLICATE: {
    text: <Trans>BUDGET_LINE_ALREADY_EXISTS</Trans>,
    logic: (error) =>
      error.includes('Budget line for this year was already created')
  }
}

Yup.addMethod(Yup.string, 'advancedTextEditorValidity', function (max) {
  return this.test('advancedTextEditorValidity', null, function (value) {
    const { path, createError } = this
    let stringValue = value || ''
    stringValue = stringValue
      .replaceAll('&nbsp;', ' ')
      .replace(/<[^>]+>/g, '')
      .replace(/\s\s+/g, ' ')
      .replaceAll('\n', '')
    if (stringValue.length > max) {
      return createError({
        path,
        message: myI18n?._(
          t`This fields length cannot exceed ${max} characters!`
        )
      })
    }
    return true
  })
})

export const getInitialTouched = (data) => {
  const toRet = {}
  if (!data?.sections) {
    return toRet
  }
  data?.sections.forEach((section) => {
    section.elements.forEach((element) => {
      initialTouchedForElement(element, toRet)
    })
  })
  return toRet
}

export const initialTouchedForElement = (item, initObj) => {
  if (item.elements) {
    item.elements.forEach((element) => {
      initialTouchedForElement(element, initObj)
    })
  } else {
    const type = item.elementType
    if (initialTouchedFormElements.includes(type)) {
      initObj[item.id] = true
    }
    if (formComponentTypes[type].initialTouchedIds) {
      const ids = formComponentTypes[type].initialTouchedIds(item)
      ids.forEach((id) => {
        _.set(initObj, id, true)
      })
    }
  }
}

/**
 * Checks if form with specific id is valid. Form is considered valid if all of its field successfull pass validation and return no errors.
 * @function
 * @category Form
 * @param {object} params
 * @param {string} params.id Url of the target form.
 * @param {string} params.formId Id of the target form template.
 * @param {string} params.pathname Pathname of the current page
 * @returns {boolean} Promise returning boolean indicating if form was successfully validated
 */
export const checkFormValidity = ({ id, formId, pathname }) => {
  return getFormPage(formId)
    .then((result) => {
      if (result.objectsConnected) {
        return connectedObjectQuery(result, {
          id
        }).then(
          ({ connectedMap, describeMap }) => {
            const initialValues = getInitialValues({
              data: result,
              connectedMap
            })
            const elementsMap = mapFormElements(result)

            const getValidFormSchema = (errors = {}) => {
              const validationSchemaRaw = constructValidationSchema({
                data: result,
                returnRaw: true,
                langVersion: 'en'
              })

              const disabledIds = getDisabledIds({
                sections: result.sections,
                elementsMap,
                values: initialValues,
                connectedMap,
                describeMap,
                errors,
                pathname
              })
              disabledIds.forEach((id) => {
                delete validationSchemaRaw[id]
              })
              return Yup.object().shape(validationSchemaRaw)
            }

            return validateYupSchema(initialValues, getValidFormSchema()).then(
              (result) => {
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema({})
                ).then(
                  (result) => true,
                  (reject) => false
                )
              },
              (reject) => {
                const errors = {}
                reject.inner.forEach((error) => {
                  errors[error.path] = error
                })
                return validateYupSchema(
                  initialValues,
                  getValidFormSchema(errors)
                ).then(
                  (result) => true,
                  (reject) => false
                )
              }
            )
          },
          (reject) => {
            console.error('no object found', reject)
            return false
          }
        )
      } else {
        return true
      }
    })
    .catch((error) => {
      console.error('error loading form', error)
      return false
    })
}

const containsSanitizableHtml = (html) => {
  const htmlTagsRegex = /<\/?[a-z][\s\S]*>/i
  const specialCharRegex = /&[a-zA-Z0-9#]{2,};/i

  return htmlTagsRegex.test(html) || specialCharRegex.test(html)
}

const sanitizeHtml = (html) => {
  const cleanedHtml = html.replace(/<figure class="table">/g, '')
    .replace(/<\/figure>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&#160;/g, ' ')
    .replace(/\u00A0/g, ' ')
    .replace(/\s+/g, '')
    .trim()

  return cleanedHtml
}

const sanitizeValues = (values) => {
  for (const key in values) {
    const value = values[key]

    if (typeof value === 'string' && containsSanitizableHtml(value)) {
      values[key] = sanitizeHtml(value)
    }
  }

  return values
}

/**
 * Checks if formik values of form are different from initial values. The additional function is required to be used in place of formik base one to exclude values that are related to the multiuser
 * @function
 * @category Form
 * @param {object} formik Formik ref
 * @returns {boolean} Boolean indicating if form values are different from initial values
 */
export const isTrueDirty = (formikOrValues) => {
  let values, initialValues

  if (formikOrValues && formikOrValues.values && formikOrValues.initialValues) {
    values = formikOrValues.values
    initialValues = formikOrValues.initialValues
  } else if (formikOrValues &&
    formikOrValues.values &&
    formikOrValues.initialValues === undefined) {
    values = formikOrValues.values
    initialValues = formikOrValues.initialValues
  } else {
    return false
  }

  if (!values || !initialValues) {
    return false
  }
  const formikValues = _.cloneDeep(values)
  const formikInitValues = _.cloneDeep(initialValues)
  delete formikValues.muInfo
  delete formikValues.muUsers
  delete formikInitValues.muInfo
  delete formikInitValues.muUsers

  const removeEditModeAndSelected = (obj) => {
    if (obj.sections && Array.isArray(obj.sections)) {
      obj.sections.forEach((section) => {
        if (section.elements && Array.isArray(section.elements)) {
          section.elements.forEach((element) => {
            delete element.editMode
            delete element.selected
          })
        }
      })
    }
    return obj
  }

  removeEditModeAndSelected(formikValues)
  removeEditModeAndSelected(formikInitValues)

  const sanitizedValues = sanitizeValues(formikValues)
  const sanitizedInitValues = sanitizeValues(formikInitValues)

  const deepEqualIgnoringUndefined = (a, b) => {
    if (_.isPlainObject(a) && _.isPlainObject(b)) {
      const allKeys = new Set([...Object.keys(a), ...Object.keys(b)])
      return [...allKeys].every((key) =>
        deepEqualIgnoringUndefined(a[key], b[key])
      )
    } else if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item, index) => deepEqualIgnoringUndefined(item, b[index]))
      )
    } else {
      return a === b || (a === undefined && b === false) || (b === undefined && a === false)
    }
  }

  if (formikOrValues.values && formikOrValues.initialValues) {
    return !deepEqualIgnoringUndefined(sanitizedInitValues, sanitizedValues)
  } else {
    return !_.isEqual(sanitizedValues, sanitizedInitValues)
  }
}

export const errorsToRender = ({
  errors,
  disabledIds,
  elementsMap,
  describeMap = {},
  objectsFieldsMap = {},
  values,
  langVersion = 'en'
}) =>
  Object.keys(errors)
    .filter((errorId) => !disabledIds.includes(errorId) && elementsMap[errorId])
    .map((errorId) => {
      const error = errors[errorId]
      const errItem = elementsMap[errorId]
      const elementProps = formComponentTypes[errItem.elementType]
      let parsedError
      let title = parseFormLabelText({
        text: errItem.title,
        langVersion,
        objectsFieldsMap,
        returnString: true,
        describeMap
      })
      if (errItem.labelAsMarkdown) {
        title = unified()
          .use(remarkParse)
          .use(remarkRehype, { allowDangerousHtml: true })
          .use(rehypeStringify, { allowDangerousHtml: true })
          .processSync(title)
          .toString()
          .replace(/<[^>]+>/g, '')
          .replaceAll('\n', '')
      }
      if (elementProps.extractError) {
        parsedError = elementProps.extractError(error, {
          item: errItem,
          value: values[errItem.id],
          langVersion
        })
      } else {
        if (Array.isArray(error)) {
          return {
            ...errItem,
            title,
            error: {
              toMap: error.map(
                (item, index) =>
                  String(index + 1) + '. ' + myI18n?._(item?.props?.id)
              )
            }
          }
        } else {
          if (error.props) {
            // parsedError = myI18n?._(error.props.id);
            parsedError = error
          } else {
            parsedError = myI18n?._(error)
          }
        }
      }
      return {
        ...errItem,
        title,
        error: parsedError
      }
    })

/**
 * Based on provided data, constructs a Yup validation schema that will be used to check correctness of the form input.
 * @function
 * @category Form
 * @param {object} params
 * @param {object} params.data Form data
 * @param {object} [params.validationInfoFromConditions={}] Object containing additional, relevant for validation information for form elements under id keys
 * @param {object} [params.requiredFromConditions=[]] Ids of fields that currently should be marked as required in form due to conditions being met
 * @param {object} [params.nonRequiredFromConditions=[]] Ids of fields that currently should not be marked as required in form due to conditions being met
 * @param {string} [params.langVersion='en'] Language version of form
 * @param {boolean} [params.returnRaw=false] If true, the function will return raw JS object instead of Yup schema object, allowing to further edit it
 * @returns {Yup.Schema} Yup validation schema
 */
export const constructValidationSchema = ({
  data,
  describeMap,
  validationInfoFromConditions = {},
  requiredFromConditions = [],
  nonRequiredFromConditions = [],
  langVersion = 'en',
  returnRaw = false,
  formikRef
}) => {
  const toRet = {}
  if (!data || !data.sections) {
    return returnRaw ? {} : Yup.object().shape({})
  }
  data.sections.forEach((section) => {
    section.elements.forEach((element) => {
      getValidationRule({
        item: element,
        obj: toRet,
        langVersion,
        requiredFromConditions,
        nonRequiredFromConditions,
        validationInfoFromConditions,
        data,
        describeMap,
        formikRef
      })
    })
  })
  if (returnRaw) {
    return toRet
  }
  return Yup.object().shape(toRet)
}

export const addYupRule = (obj, id, rule) => {
  if (obj[id]) {
    obj[id] = obj[id].concat(rule)
  } else {
    obj[id] = rule
  }
}

const getValidationRule = ({
  item,
  obj,
  langVersion,
  requiredFromConditions,
  nonRequiredFromConditions,
  validationInfoFromConditions,
  data,
  describeMap,
  formikRef
}) => {
  if (item.elements) {
    item.elements.forEach((item) => {
      getValidationRule({
        item,
        obj,
        langVersion,
        requiredFromConditions,
        nonRequiredFromConditions,
        validationInfoFromConditions,
        data,
        describeMap,
        formikRef
      })
    })
  } else {
    const type = item.elementType

    const props = _.cloneDeep(item.typeProps)

    if (validationInfoFromConditions[item.id]) {
      Object.entries(validationInfoFromConditions[item.id]).forEach(
        ([key, v]) => {
          props[key] = v
        }
      )
    }

    const {
      maxOptions,
      required,
      maxChar,
      options,
      regexValidation,
      regexValidationMessage,
      isUrl,
      isEmail,
      picklistType,
      requiredAll,
      minFiles,
      isAdvancedTextEditor
    } = props

    if (type === 'textInput') {
      if (regexValidation) {
        addYupRule(
          obj,
          item.id,
          Yup.string()
            .ensure()
            .matches(new RegExp(regexValidation), {
              excludeEmptyString: true,
              message: parseFormLabelText({
                text: regexValidationMessage,
                langVersion
              })
            })
        )
      }
      if (maxChar) {
        if (isAdvancedTextEditor) {
          addYupRule(
            obj,
            item.id,
            Yup.string().advancedTextEditorValidity(maxChar)
          )
        } else {
          addYupRule(
            obj,
            item.id,
            Yup.string()
              .ensure()
              .max(maxChar, ({ max }) =>
                myI18n?._(
                  t`This fields length cannot exceed ${max} characters!`
                )
              )
          )
        }
      }
      if (isUrl) {
        addYupRule(
          obj,
          item.id,
          Yup.string().default('').ensure().url(requiredSite)
        )
      } else if (isEmail) {
        addYupRule(
          obj,
          item.id,
          Yup.string()
            .ensure()
            .email(() => emailTrans)
        )
      }
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addYupRule(obj, item.id, Yup.string().required(questionRequiredTrans))
      }
    } else if (type === 'textInputNumeric') {
      handleRulesFormNumericInputValidation({
        typeProps: props,
        id: item.id,
        schema: obj,
        requiredFromConditions,
        nonRequiredFromConditions
      })
    } else if (type === 'picklist' && picklistType === 'multiselect') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addYupRule(
          obj,
          item.id,
          Yup.array()
            .nullable()
            .compact()
            .min(1, questionRequiredTrans)
            .required(questionRequiredTrans)
        )
      } else if (
        (requiredAll && !nonRequiredFromConditions.includes(item.id)) ||
        (requiredAll && requiredFromConditions.includes(item.id))
      ) {
        const min = maxOptions ? Number(maxOptions) : options.length
        addYupRule(
          obj,
          item.id,
          Yup.array()
            .compact()
            .min(
              min,
              maxOptions
                ? (
                  <Trans>You must select {min} checkboxes</Trans>
                  )
                : (
                  <Trans>You must select all checkboxes!</Trans>
                  )
            )
        )
      }
    } else if (type === 'uploadFiles') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id) ||
        minFiles
      ) {
        const minNumOfFiles = minFiles ? Number(minFiles) : 1

        addYupRule(
          obj,
          item.id,
          Yup.array()
            .nullable()
            .compact((file) => file?.toBeDeleted)
            .meta({
              current: minNumOfFiles
            })
            .min(minNumOfFiles, ({ min, value }) => {
              const desired = min
              const current = value.length
              return desired - current === 1
                ? myI18n?._(t`You need to upload at least one file!`)
                : myI18n?._(
                  t`You need to upload at least ${minNumOfFiles} file!`
                )
            })
        )
      }
    } else if (type === 'bool') {
      if (
        (required && !nonRequiredFromConditions.includes(item.id)) ||
        requiredFromConditions.includes(item.id)
      ) {
        addYupRule(
          obj,
          item.id,
          Yup.boolean().equals([true], questionRequiredTrans)
        )
      }
    } else {
      const elementProps = formComponentTypes[type]
      if (elementProps) {
        if (elementProps.validation) {
          addYupRule(
            obj,
            item.id,
            elementProps.validation(item, data, describeMap, formikRef)
          )
        } else {
          if (
            (required && !nonRequiredFromConditions.includes(item.id)) ||
            requiredFromConditions.includes(item.id)
          ) {
            addYupRule(
              obj,
              item.id,
              Yup.mixed().required(questionRequiredTrans)
            )
          }
        }
      }
    }
  }
}
