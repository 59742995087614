import { Trans, t } from '@lingui/macro'
import { myI18n } from 'translation/I18nConnectedProvider'

const keyToLabel = {
  change: <Trans>BUDGET_UPDATE_CHANGE_LABEL</Trans>,
  changeDetails: <Trans>BUDGET_UPDATE_CHANGE_COMMENT_LABEL</Trans>,
  actual: <Trans>BUDGET_UPDATE_ACTUAL_LABEL</Trans>,
  actualComments: <Trans>BUDGET_UPDATE_ACTUAL_COMMENT_LABEL</Trans>
}
/** This is a helper component to convert an object with errors to a list
 * @param {object} item - The object with errors to convert to a list
 * @returns {JSX.Element} - The error list component */
const FormBudgetUpdateErrorList = ({ item }) => {
  if (!item) return null

  return (
    <ul>
      {Object.keys(item).map((key, index) => {
        const errorObj = item[key]
        return (
          <li key={index}>
            <b>{keyToLabel[key]}</b>: <Trans id={errorObj?.props?.id} />
          </li>
        )
      })}
    </ul>
  )
}

export default function FormBudgetUpdateError (error) {
  if (!error) {
    return null
  }
  return (
    <div>
      {error.report?.hasBudgetChanged && (<div style={{ marginBottom: 16, marginLeft: 8 }}>
        <b><Trans>BUDGET_UPDATE_UNEQUAL_EXPENSES_REVENUES_SUMS_LABEL</Trans></b>: {error.report?.hasBudgetChanged}
      </div>)}
      {error.expensesLines && (
        <>
          <b style={{ marginLeft: 8 }}>
            <Trans>BUDGET_UPDATE_EXPENSES</Trans>
          </b>
          <ul>
            {Object.keys(error.expensesLines)
              .filter((key) => error.expensesLines[key])
              .map((key) => {
                const budgetUpdateChangeObj =
                  error.expensesLines[key]?.budgetUpdateChange
                const budgetUpdateActualObj =
                  error.expensesLines[key]?.budgetUpdateActual
                return (
                  <li key={key}>
                    <u>{myI18n._(t`${key}`)}</u>
                    <FormBudgetUpdateErrorList item={budgetUpdateChangeObj} />
                    <FormBudgetUpdateErrorList item={budgetUpdateActualObj} />
                  </li>
                )
              })}
          </ul>
        </>
      )}
      {error.revenuesLines && (
        <>
          <b style={{ marginLeft: 8 }}>
            <Trans>BUDGET_UPDATE_REVENUES</Trans>
          </b>
          <ul>
            {Object.keys(error.revenuesLines)
              .filter((key) => error.revenuesLines[key])
              .map((key) => {
                const budgetUpdateChangeObj =
                  error.revenuesLines[key]?.budgetUpdateChange
                const budgetUpdateActualObj =
                  error.revenuesLines[key]?.budgetUpdateActual
                return (
                  <li key={key}>
                    <u>{myI18n._(t`${key}`)}</u>
                    <FormBudgetUpdateErrorList item={budgetUpdateChangeObj} />
                    <FormBudgetUpdateErrorList item={budgetUpdateActualObj} />
                  </li>
                )
              })}
          </ul>
        </>
      )}
    </div>
  )
}
