import { FormConnectToObject } from 'app/views/forms/common/FormConnectToObject'
import { PdfPropsForm } from 'app/views/forms/common/PdfPropsForm'
import { useDispatch } from 'react-redux'
import { formComponentTypes } from '../../formComponentTypes'
import { FormObjectivesUpdate } from './FormObjectivesUpdate'
import { Grid } from '@material-ui/core'
import { Trans } from '@lingui/macro'
import DebouncedTextField from 'app/views/forms/common/DebouncedTextField'
import { NumberFormatDefault } from 'app/views/forms/common/Common'

export const FormEditorObjectivesUpdate = ({
  id,
  showPrintProps,
  editMode,
  depth,
  typeProps = {},
  injectable,
  injectableId,
  showPdfProps,
  disabled,
  ...props
}) => {
  const dispatch = useDispatch()

  if (!editMode) {
    return (
      <FormObjectivesUpdate editMode id={id} typeProps={typeProps} {...props} />
    )
  }

  return (
    <div>
      {showPdfProps && (
        <PdfPropsForm
          typeProps={typeProps}
          dispatch={dispatch}
          injectable={injectable}
          depth={depth}
        />
      )}

      <Grid container>
        {[
          {
            key: 'indentifiedNeedsCustomLimit',
            label: <Trans>FORM_OBJECTIVES_UPDATE_IDENTIFIED_NEEDS_CHANGES_LENGTH_LIMIT</Trans>
          },
          {
            key: 'desiredOutcomeCustomLimit',
            label: <Trans>FORM_OBJECTIVES_UPDATE_DESIRED_OUTCOME_CHANGES_LENGTH_LIMIT</Trans>
          },
          {
            key: 'actualOutcomeCustomLimit',
            label: <Trans>FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES_LENGTH_LIMIT</Trans>
          }
        ].map((obj, index) => {
          return (
            <Grid item xs key={index} style={{ padding: 5 }}>
              <DebouncedTextField
                style={{ marginBottom: 10 }}
                label={obj.label}
                value={typeProps[obj.key] || ''}
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatDefault
                }}
                variant='outlined'
                disabled={disabled}
                onChange={(e) => {
                  const toSet = { ...typeProps }
                  toSet[obj.key] = e.target.value
                  dispatch({
                    type: 'FIELD',
                    injectable,

                    depth: depth.split('.'),
                    fieldName: 'typeProps',
                    fieldValue: toSet
                  })
                }}
              />
            </Grid>
          )
        })}
      </Grid>
      <FormConnectToObject
        injectable={injectable}
        injectableId={injectableId}
        typeProps={typeProps}
        depth={depth}
        noField
        objectsConnections={
          formComponentTypes.objectivesUpdate.connectsToMultipleObjects
        }
      />
    </div>
  )
}
