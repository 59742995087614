import { t } from '@lingui/macro'
import { pdf } from '@react-pdf/renderer'
import React from 'react'
import { myI18n } from 'translation/I18nConnectedProvider'
import { isPureBooleanTS } from '../../../../utilsTS'
import { formConditions } from '../editor/ConditionalElementEditor'

export function purgeInvalidPDFSignFromString (text: string): string {
  return isPureBooleanTS(text)
    ? String(text).replaceAll(
      /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u200b\u202f\u205f​\u3000\u0009\uF000-\uFFFF]/g,
      ' '
    )
    : ''
}

export function parsePDFLabelToSupportWordBreaking (text: string): string[] | string {
  return isPureBooleanTS(text) ? text.split('') : ''
}

interface ConditionConfigProps {
  condition: string
  conditionTarger: string
  parameter: string
  targetIndex: string
  elementType: string
  affectedElementType: string
  state:
  | 'show'
  | 'hide'
  | 'altLabel'
  | 'altHelpText'
  | 'required'
  | 'notRequired'
}

export function formConditionToText (conditionConfig: ConditionConfigProps): string {
  let toRet = ''
  const { state, condition, elementType, affectedElementType, parameter } =
    conditionConfig
  if (condition === null || condition === undefined) {
    return toRet
  }

  if (state === 'show') {
    toRet =
      affectedElementType === 'uploadFiles'
        ? String(myI18n._(t`PDF_CONDITION_IF`)) + ' '
        : String(myI18n._(t`PDF_CONDITION_ANSWER_IF`)) + ' '
  } else if (state === 'hide') {
    toRet = String(myI18n._(t`PDF_CONDITION_DO_NOT_ANSWER_IF`)) + ' '
  } else {
    return toRet
  }
  const config = formConditions[elementType]?.conditions[condition]
  if (isPureBooleanTS(config?.manualFillText)) {
    toRet += String(myI18n._(config.manualFillText))
    if (isPureBooleanTS(parameter)) {
      toRet += ` '${parameter}' `
    }
  }
  toRet +=
    String(myI18n._(t`PDF_CONDITION_IN_QUESTION`)) + ` ${conditionConfig.targetIndex}.`

  return toRet
}

export const downloadOrphanedPDF = async (
  pdfDocument: React.ReactElement,
  fileName: string
): Promise<boolean> => {
  document.body.style.cursor = 'wait'
  return await pdf(pdfDocument)
    .toBlob()
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = fileName + '.pdf'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      document.body.style.cursor = ''
      return true
    }, (reject) => false)
}
