import SFAuthService, { NO_USER } from '../SFAuthService'
import sfOauthConfig from '../sfAuthConfig'

/**
 * Retrieves payments associated with a specific Opportunity.
 * @function
 * @category Salesforce - FGM_Base__Payment__c and Opportunity__c
 * @param {string} opportunityId The id of the Opportunity to retrieve payments for.
 * @returns {Payment[]}
 */
export const getPaymentsForOpportunity = (opportunityId: string) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  const selectFields = [
    'FGM_Base__Scheduled_Payment_Date__c',
    'FGM_Base__Grantee_Report__c',
    'FGM_Base__Status__c',
    'FGM_Base__Amount__c',
    'Name',
    'Id',
    'Percentage_New__c',
    'Percentage_feasibility__c',
    'Percentage_pre_development__c',
    'Special_type__c'
  ]
  if (!sfOauthConfig.isIcce) {
    selectFields.push('FGM_Base__Awarded_Amount__c')
  }
  return conn
    .sobject('FGM_Base__Payment__c')
    .find({
      FGM_Base__Request__c: opportunityId
    })
    .select(selectFields)
}

interface PaymentValues {
  id: string,
  dueDate?: Date | moment.Moment,
  base?: number
}

/**
 * Updates payment information in Salesforce.
 * @function
 * @category Salesforce - FGM_Base__Payment__c
 * @param {object} values The payment information to be updated.
 * @param {string} id The id of the payment record to be updated.
 * @param {Date | moment.Moment} dueDate The new scheduled payment date.
 * @param {number} base - The new amount for the payment.
 * @returns {JSForceResult}
 */
export const savePayment = (values: PaymentValues) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').update({
    Id: values.id,
    FGM_Base__Scheduled_Payment_Date__c: values.dueDate,
    FGM_Base__Amount__c: values.base
  })
}

interface CreatePaymentData {
  opportunityId: string,
  reportId: string,
  base: number,
  dueDate: Date | moment.Moment
}

/**
 * Creates payments in Salesforce based on the provided array of payment data.
 * @function
 * @category Salesforce - FGM_Base__Payment__c
 * @param {Array<CreatePaymentData>} array An array of create payment data objects.
 * @param {string} array[].opportunityId The id of the Opportunity associated with the payment.
 * @param {string} array[].reportId The id of the Grantee Report associated with the payment.
 * @param {number} array[].base The amount for the payment.
 * @param {Date | moment.Moment} array[].dueDate The scheduled payment date.
 * @returns {JSForceResult}
 */
export const createPayments = (array: CreatePaymentData[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').create(
    array.map(ob => {
      return {
        FGM_Base__Payment_Type__c: 'Wire Transfer',
        FGM_Base__Request__c: ob.opportunityId,
        FGM_Base__Grantee_Report__c: ob.reportId,
        FGM_Base__Amount__c: ob.base,
        FGM_Base__Scheduled_Payment_Date__c: ob.dueDate
      }
    })
  )
}

/**
 * Creates a payment in Salesforce.
 * @function
 * @category Salesforce - FGM_Base__Payment__c
 * @param {CreatePaymentData} params Parameters for creating the payment.
 * @param {string} params.opportunityId The id of the Opportunity associated with the payment.
 * @param {string} params.reportId The id of the Grantee Report associated with the payment.
 * @param {number} params.base The amount for the payment.
 * @param {Date | moment.Moment} params.dueDate The scheduled payment date.
 * @returns {JSForceResult}
 */
export const createPayment = ({ opportunityId, reportId, dueDate, base }: CreatePaymentData) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn.sobject('FGM_Base__Payment__c').create({
    FGM_Base__Request__c: opportunityId,
    FGM_Base__Grantee_Report__c: reportId,
    FGM_Base__Amount__c: base,
    FGM_Base__Scheduled_Payment_Date__c: dueDate
  })
}

/**
 * Retrieves payments associated with the specified grantee reports.
 * @function
 * @category Salesforce - FGM_Base__Payment__c and FGM_Base__Grantee_Report__c
 * @param {string[]} reportsIds An array of grantee report ids to retrieve payments for.
 * @returns {Payment[]}
 */
export const getPayments = (reportsIds: string[]) => {
  const conn = SFAuthService.getConnection()
  if (!conn) {
    return Promise.reject(NO_USER)
  }
  return conn
    .sobject('FGM_Base__Payment__c')
    .find({
      FGM_Base__Grantee_Report__c: { $in: [...reportsIds] }
    })
    .select(
      'FGM_Base__Scheduled_Payment_Date__c, FGM_Base__Grantee_Report__c, FGM_Base__Status__c, FGM_Base__Amount__c, FGM_Base__Awarded_Amount__c, Name, Id, Percentage_New__c'
    )
}
