import { muiTextLabels } from 'app/views/utilities/muiDataTablesTranslations'
import MUIDataTable from 'mui-datatables'
import { myI18n } from 'translation/I18nConnectedProvider'
import { muiDateCompare } from 'utils'
import { parseFormLabelText, parseSfFieldValue } from '../../common/Common'
import { formConditions } from '../../editor/ConditionalElementEditor'
import { getFilteredCollection } from './FormTableUtils'

/**
 * Form element which renders a table displaying
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {Object[]} typeProps.columns Columns defined in editor, that will be used to render a table, which contains a collection of SF objects connected in the editor.
 */
export const FormTable = ({
  title,
  langVersion,
  connectedMap,
  i18n,
  typeProps
}) => {
  const { connectedTo = [], columns = [], tableFilters = [] } = typeProps
  let collection = []
  const collectionKey = connectedTo[0]?.connectedCollection
  const connectedObject = connectedTo[0]?.connectedObject
  if (collectionKey && connectedObject && connectedMap) {
    collection =
      connectedMap[connectedObject]?.additionalInfo[collectionKey] || []
  }

  const filteredCollection = getFilteredCollection(collection, tableFilters)

  return (
    <MUIDataTable
      title={title}
      data={filteredCollection.map((obj, index) => {
        const toRet = {}
        columns.forEach(column => {
          const { field, translate } = column
          if (field.name) {
            const fieldValue = obj[field.name]
            toRet[field.name] = parseSfFieldValue({
              value: fieldValue,
              type: field.type,
              langVersion,
              translate
            })
          }
        })
        return toRet
      })}
      options={{
        textLabels: muiTextLabels(myI18n),
        selectableRows: 'none',
        viewColumns: false
      }}
      columns={columns
        .map((column, index) => {
          const { field, label } = column
          const parsedLabel = parseFormLabelText({
            langVersion,
            i18n,
            label
          })
          return {
            name: field.name,
            label: parsedLabel,
            options: {
              customHeadLabelRender: props => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>
                    {parsedLabel}
                  </div>
                )
              },
              sortCompare: field.type === 'date' && muiDateCompare(),
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div style={{ marginLeft: index === 0 && 20 }}>{value}</div>
                )
              }
            }
          }
        })
        .filter(column => column.name)}
    />
  )
}
