import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { pdfDefaultFontSize } from '../../common/Common'

const styles = StyleSheet.create({
  main: {
    padding: 6
  },
  emptyDate: {
    fontSize: pdfDefaultFontSize
  }
})

export const FormDatePickerFillablePdf = ({
  value,
  elementType,
  typeProps,
  langVersion,
  title,
  helpText,
  altLabelPlacement,
  tooltip,
  ...props
}) => {
  return (
    <View style={styles.main}>
      <Text style={styles.emptyDate}>
        ________ / ____ / ____
      </Text>
    </View>
  )
}
