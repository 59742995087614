import { dateFormat } from "app/appSettings";
import CustomDatePicker from "app/views/common-components/CustomDatePicker";

/** Represents the condition item structure. */
interface ConditionItem {
  parameter?: string | null
}

/** Props for the ConditionDatePickerField component. */
interface ConditionDatePickerFieldProps {
  item: ConditionItem;
  onChange: (value: string | null) => void;
}

/**
 * A component for rendering a date picker field for a given condition.
 *
 * @param {ConditionDatePickerFieldProps} props - The properties for the component.
 * @returns {JSX.Element} A date picker field for managing condition dates.
 */

export default function ConditionDatePickerField({
  item,
  onChange,
}: ConditionDatePickerFieldProps): JSX.Element {
  return (
    <CustomDatePicker
      inputVariant="outlined"
      fullWidth
      format={dateFormat}
      value={item.parameter || null}
      onChange={(e) => onChange(e)}
    />
  )
}