import { Trans } from '@lingui/macro'
import { Button, Typography } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormLabel } from '../common/labels/FormLabel'
import { multiuserColoredInputClasses } from '../forms/multiuser/components/muStyles'

interface CustomDatePickerProps {
  color?: string;  // Adjusted type
  handleClose?: (selectedDate?: any) => void;
  handleOpen?: () => void;
  minDate?: any;   // Allow for any value type or specific date types
  maxDate?: any;   // Allow for any value type or specific date types
  [x: string]: any; // Allow any additional props
}

/**
 * Extended Material UI DatePicker that handles portal localization and overriding theme color. Is recommended to use over regular <DatePicker /> component.
 * @category Common components
 * @subcategory MUI extended
 * @component
 * @returns {JSX.Element}
 * @property {color} color Focus color of the field
 * @property {function} handleClose Callback fired on inputs onClose event
 * @property {function} handleOpen Callback fired on inputs onOpen event
 */
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ color, handleClose, handleOpen, ...props }) => {
  const userLang = useSelector((state: { user: { language: string } }) => state.user.language)
  const settings = useSelector((state: { layout: { settings: any } }) => state.layout.settings)
  const [dialogDateSelected, setDialogDateSelected] = useState<any>()
  const theme = settings.themes[settings.activeTheme]
  const classes = multiuserColoredInputClasses(color)

  return (
    <>
      <DatePicker
        value={undefined}
        onChange={() => {}}
        cancelLabel={<Trans>Cancel</Trans>}
        okLabel={<Trans>Ok</Trans>}
        clearLabel={<Trans>Clear</Trans>}
        {...props}
        minDate={props.minDate || undefined}
        maxDate={props.maxDate || undefined}
        onOpen={() => {
          if (handleOpen) {
            handleOpen()
          }
        }}
        onClose={() => {
          if (handleClose) {
            handleClose(dialogDateSelected)
          }
        }}
        className={color && classes.root}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
        renderDay={(day, selectedDay, dayInCurrentMoth, dayComponent) => {
          return (
            <span
              style={{ display: 'flex' }}
              onClick={e => {
                setDialogDateSelected(day)
              }}
            >
              {dayComponent}
            </span>
          )
        }}
        ToolbarComponent={t => {
          const { date, setOpenView } = t
          let dateString = date?.locale(userLang).format('dddd, D MMMM')
          
          if (userLang === 'fr') {
            const month = date?.format('MMMM')
            const day = date?.format('D')
            const weekday = date?.format('dddd')
            
            // Check if all values are available (i.e., date is not undefined)
            if (month && day && weekday) {
              const formattedMonth = month[0].toLowerCase() + month.slice(1)
              const formattedWeekday = weekday[0].toUpperCase() + weekday.slice(1)
          
              dateString = `${formattedWeekday} ${day} ${formattedMonth}`
            }
          }
          
          return (
            <div
              style={{
                padding: 24,
                height: 100,
                backgroundColor: theme.palette.primary.main
              }}
            >
              <div>
                <Button
                  onClick={e => {
                    setOpenView('year')
                  }}
                  style={{
                    padding: 0,
                    justifyContent: 'flex-start',
                    textAlign: 'left'
                  }}
                >
                  <Typography style={{ fontSize: 16 }}>
                    {moment(date).year()}
                  </Typography>
                </Button>
              </div>

              <div>
                <Button
                  style={{ padding: 0 }}
                  onClick={e => {
                    setOpenView('date')
                  }}
                >
                  <Typography variant='h4'>{dateString}</Typography>
                </Button>
              </div>
            </div>
          )
        }}
      />
      {props.error && props.metaError && (
        <FormLabel error label={<Trans id={props.metaError?.props?.id} />} />
      )}
    </>
  )
}

export default CustomDatePicker
