import { t } from "@lingui/macro";
import { myI18n } from "translation/I18nConnectedProvider";
import { pdfTableStyles } from "app/views/forms/pdf-components/PDFCommon";
import React from "react";
import {
  defaultFormPdfFontFamily,
  inuktitutFormPdfFontFamily
} from "app/views/forms/pdf-components/FormPdf";

const styles = {
  table: {
    ...pdfTableStyles.table,
    width: '100%'
  } as React.CSSProperties,
  tableRow: {
    ...pdfTableStyles.tableRow,
    display: 'flex'
  } as React.CSSProperties,
  firstCol: {
    width: '35%'
  },
  secondCol: {
    width: '20%'
  },
  thirdCol: {
    width: '45%'
  },
  tableCol: {
    ...pdfTableStyles.tableCol,
    minHeight: 35
  },
  tableCell: {
    ...pdfTableStyles.tableCell,
    margin: '2px',
    padding: '10px 20px'
  } as React.CSSProperties,
  secondTitle: {
    fontFamily: defaultFormPdfFontFamily,
    fontSize: 14,
    margin: '2px',
    textAlign: 'center',
    marginBottom: '5px'
  },
  mainTableCell: {
    ...pdfTableStyles.mainTableCell
  } as React.CSSProperties,
  helptextCell: {
    ...pdfTableStyles.helptextCell,
    padding: '0 20px 10px 20px',
    marginTop: '-12px'
  } as React.CSSProperties
};

interface FormContactPickerFillablePDFEditorProps {
  value?: object | string | undefined;
  elementType: string;
  langVersion: string;
  title?: string | undefined;
  helpText?: string | undefined;
  tooltip?: string | undefined;
  altLabelPlacement?: string | undefined;
  typeProps?: object | undefined;
}

/**
 * A React component that renders a preview of the fillable PDF version of contact picker 
 * form element in form editor.
 * It displays a table with columns for first name, last name, job title, email, 
 * and phone number.
 * 
 * @component
 * 
 * @param {Object | string | undefined} props.value - The value to populate the form fields with (optional).
 * @param {string} props.elementType - A string identifier for the element type.
 * @param {string} props.langVersion - The language version to use for translations.
 * @param {string} [props.title] - The optional title of the form element.
 * @param {string} [props.helpText] - Optional help text that explains how to fill the field.
 * @param {string} [props.tooltip] - Optional tooltip text.
 * @param {string} [props.altLabelPlacement] - Optional placement for the label.
 * @param {Object} [props.typeProps] - Optional additional properties to customize the form element behavior.
 * 
 * @returns {JSX.Element} A `View` component representing the form element in a fillable 
 * PDF layout.
 */
export const FormContactPickerFillablePDFEditor: React.FC<FormContactPickerFillablePDFEditorProps> = ({
  value,
  elementType,
  langVersion,
  title,
  helpText,
  tooltip,
  altLabelPlacement,
  typeProps,
  ...props
}) => {
  const columnStyles = {
    ...pdfTableStyles.tableCol,
    width: "20%",
    minHeight: 36
  } as React.CSSProperties;

  return (
    <div style={styles.table}>
      <div style={styles.tableRow}>
        <div style={columnStyles}>
          <div style={{
            ...styles.mainTableCell,
            fontFamily: langVersion === 'iu-cans' ?
              inuktitutFormPdfFontFamily :
              defaultFormPdfFontFamily,
          }}
          >
            <span>{myI18n._(t`First Name`)}</span>
          </div>
        </div>
        <div style={columnStyles}>
          <div style={{
            ...styles.mainTableCell,
            fontFamily: langVersion === 'iu-cans' ?
              inuktitutFormPdfFontFamily :
              defaultFormPdfFontFamily,
          }}
          >
            <span>{myI18n._(t`Last Name`)}</span>
          </div>
        </div>
        <div style={columnStyles}>
          <div style={{
            ...styles.mainTableCell,
            fontFamily: langVersion === 'iu-cans' ?
              inuktitutFormPdfFontFamily :
              defaultFormPdfFontFamily,
          }}
          >
            <span>{myI18n._(t`Job title`)}</span>
          </div>
        </div>
        <div style={columnStyles}>
          <div style={{
            ...styles.mainTableCell,
            fontFamily: langVersion === 'iu-cans' ?
              inuktitutFormPdfFontFamily :
              defaultFormPdfFontFamily,
          }}
          >
            <span>{myI18n._(t`E-mail`)}</span>
          </div>
        </div>
        <div style={columnStyles}>
          <div style={{
            ...styles.mainTableCell,
            fontFamily: langVersion === 'iu-cans' ?
              inuktitutFormPdfFontFamily :
              defaultFormPdfFontFamily,
          }}
          >
            <span>{myI18n._(t`Phone`)}</span>
          </div>
        </div>
      </div>
      <div style={styles.tableRow as React.CSSProperties}>
        <div style={columnStyles} />
        <div style={columnStyles} />
        <div style={columnStyles} />
        <div style={columnStyles} />
        <div style={columnStyles} />
      </div>
    </div>
  );
};
