import { Trans } from '@lingui/macro'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import {
  createBudgetUpdateParsed,
  parseBudgetUpdateSFObject,
  updateBudgetUpdateParsed
} from 'app/services/sfAuth/sfData/reports/sfBudgetUpdate'
import { saveReportParsed } from 'app/services/sfAuth/sfData/sfReports'
import { DialogTitleWithIconClose } from 'app/views/common-components/DialogTitleWithIconClose'
import { currencyFormatedString } from 'app/views/common/Formats'
import { FormHelptext } from 'app/views/forms/common/FormHelpText'
import { requiredTrans } from 'app/views/forms/formTranslations'
import MUTextField from 'app/views/forms/multiuser/components/MUTextField'
import { useField, useFormikContext } from 'formik'
import _ from 'lodash'
import { useState } from 'react'
import { isNumberOrZero } from 'utils'
import * as Yup from 'yup'
import { budgetCentreGrantKey, isBudgetLineValid, sanitizeLineKey } from '../budget/FormBudget'
import { BUDGET_EXPENSES_LINES_IN_ORDER, BUDGET_REVENUES_LINES_IN_ORDER } from '../budget/FormBudgetUtils'

export const isLatestUpdateValid = (value) =>
  Object.values({ ...value.expensesLines, ...value.revenuesLines }).some(
    ({ applicationBudget, latestUpdate }) => {
      if (latestUpdate && latestUpdate.change) {
        return latestUpdate.change !== applicationBudget.value
      }
      return false
    }
  )

export function formBudgetUpdateDefaultValue (obj, { enqueueSnackbar }) {
  const opportunityObj = obj.Opportunity
  const reportObj = obj.FGM_Base__Grantee_Report__c
  const toRet = {}
  const categoryInfoMapping = {}
  let latestUpdateIsASourceOfDefaultValue = false

  if (!opportunityObj || !reportObj) {
    return toRet
  }

  if (opportunityObj) {
    if (!opportunityObj.FGM_Portal__Grantee_Budget_Line_Items__r ||
      (opportunityObj.FGM_Portal__Grantee_Budget_Line_Items__r?.records || []).length === 0) {
      throw new Error(<Trans>FORM_BUDGET_UPDATE_NO_GRANTEE_BUDGET_LINES_ERROR_LABEL</Trans>)
    }
    if (!opportunityObj.FGM_Portal__Grantee_Budget_Line_Items__r) {
      enqueueSnackbar(
        <Trans>LOADING_ERROR_BUDGET_UPDATE_NO_BUDGET_LINES</Trans>,
        {
          variant: 'error'
        }
      )
      return toRet
    }
    const noCategory =
      opportunityObj.FGM_Portal__Grantee_Budget_Line_Items__r?.records.some(
        (obj) => !obj.FGM_Portal__Category__r
      )
    if (noCategory) {
      enqueueSnackbar(
        <Trans>LOADING_ERROR_BUDGET_UPDATE_NO_PARENT_CATEGORY</Trans>,
        {
          variant: 'error'
        }
      )
      return toRet
    }
    opportunityObj.FGM_Portal__Grantee_Budget_Line_Items__r?.records.forEach(
      (obj) => {
        const type =
          obj.FGM_Portal__Category__r?.FGM_Portal__Parent_Category__r.Name

        if (!isBudgetLineValid(obj)) {
          return
        }

        const value = obj.FGM_Portal__Amount__c || 0
        const lineKey = sanitizeLineKey(obj.FGM_Portal__Category__r.Name)
        const comment = obj.FGM_Portal__Note__c
        const isExpense = type === 'Expenses'
        const categoryId = obj.FGM_Portal__Category__r.Id
        categoryInfoMapping[categoryId] = {
          lineKey,
          categoryId,
          isExpense
        }

        const targetPath = isExpense
          ? `expensesLines.${lineKey}`
          : `revenuesLines.${lineKey}`

        const existingData = _.get(toRet, `${targetPath}`)

        // We are setting the base line value based on first budget line of given category
        if (!existingData) {
          _.set(toRet, targetPath, {
            categoryId,
            defaultValue: {
              value,
              comment
            },
            applicationBudget: {
              value,
              comments: comment ? [comment] : []
            }
          })
        } else {
          existingData.applicationBudget.value += value
          existingData.defaultValue.value += value

          if (comment) {
            existingData.applicationBudget.comments.push(comment)
          }
          _.set(toRet, targetPath, existingData)
        }
      }
    )

    const latestReport = opportunityObj.granteeReports.find(report => {
      return Object.entries({ ...toRet.expensesLines, ...toRet.revenuesLines }).some(
        ([lineKey, { applicationBudget }]) => {
          const updateLine = report.budgetUpdates.find(update => lineKey === categoryInfoMapping[update.budgetCategory]?.lineKey)
          return Boolean(updateLine?.change && (updateLine.change !== applicationBudget.value))
        }
      )
    })

    if (latestReport && latestReport.budgetUpdates?.length > 0) {
      toRet.latestReport = latestReport
      latestUpdateIsASourceOfDefaultValue = true
      latestReport.budgetUpdates
        .filter((obj) => categoryInfoMapping[obj.budgetCategory])
        .forEach((budgetUpdate) => {
          const { lineKey, isExpense } =
            categoryInfoMapping[budgetUpdate.budgetCategory]
          const targetPath = isExpense
            ? `expensesLines.${lineKey}`
            : `revenuesLines.${lineKey}`
          _.set(toRet, targetPath + '.latestUpdate', budgetUpdate)
          _.set(toRet, targetPath + '.defaultValue', {
            value: budgetUpdate.change,
            comment: budgetUpdate.changeDetails
          })
        })
    }
  }

  if (reportObj) {
    toRet.report = {
      id: reportObj.Id,
      status: reportObj.FGM_Base__Status__c,
      hasBudgetChanged: reportObj.Budget_changes__c || 'Yes'
    }

    if (reportObj.Budget_Updates__r) {
      reportObj.Budget_Updates__r.records.forEach((sfBudgetUpdate) => {
        const budgetUpdate = parseBudgetUpdateSFObject(sfBudgetUpdate)
        if (categoryInfoMapping[budgetUpdate.budgetCategory]) {
          const { lineKey, isExpense } =
            categoryInfoMapping[budgetUpdate.budgetCategory]

          const changeTargetPath = isExpense
            ? `expensesLines.${lineKey}.budgetUpdateChange`
            : `revenuesLines.${lineKey}.budgetUpdateChange`

          _.set(toRet, changeTargetPath, {
            id: budgetUpdate.id,
            change: budgetUpdate.change,
            changeDetails: budgetUpdate.changeDetails
          })

          const actualTargetPath = isExpense
            ? `expensesLines.${lineKey}.budgetUpdateActual`
            : `revenuesLines.${lineKey}.budgetUpdateActual`
          let actual = budgetUpdate.actual
          if (lineKey === budgetCentreGrantKey && !isNumberOrZero(actual)) {
            actual = opportunityObj.Actual_Payments_Made__c
          }
          _.set(toRet, actualTargetPath, {
            id: budgetUpdate.id,
            actual,
            actualComments: budgetUpdate.actualComments
          })
        } else {
          console.warn(
            'There is no budget line on Opportunity matching budget category of existing budget update object!'
          )
        }
      })
    } else {
      _.set(toRet, `revenuesLines.${budgetCentreGrantKey}.budgetUpdateActual`, {
        actual: opportunityObj.Actual_Payments_Made__c,
        actualComments: ''
      })
    }
  }

  // Add default values to not created budget update lines

  if (toRet.expensesLines) {
    Object.entries(toRet.expensesLines).forEach(([lineKey, lineData]) => {
      const { budgetUpdateChange, applicationBudget, latestUpdate } = lineData
      const useLatest = latestUpdate && latestUpdateIsASourceOfDefaultValue
      if (!budgetUpdateChange) {
        _.set(toRet, `expensesLines.${lineKey}.budgetUpdateChange`, {
          change: useLatest ? latestUpdate.change : applicationBudget.value,
          changeDetails: useLatest
            ? latestUpdate.changeDetails
            : Array.isArray(applicationBudget.comments)
              ? applicationBudget.comments.join('\n')
              : ''
        })
      }
    })
  }

  if (toRet.revenuesLines) {
    Object.entries(toRet.revenuesLines).forEach(([lineKey, lineData]) => {
      const { budgetUpdateChange, applicationBudget, latestUpdate } = lineData
      const useLatest = latestUpdate && latestUpdateIsASourceOfDefaultValue
      if (!budgetUpdateChange) {
        _.set(toRet, `revenuesLines.${lineKey}.budgetUpdateChange`, {
          change: useLatest ? latestUpdate.change : applicationBudget.value,
          changeDetails: useLatest
            ? latestUpdate.changeDetails
            : Array.isArray(applicationBudget.comments)
              ? applicationBudget.comments.join('\n')
              : ''
        })
      }
    })
  }
  // If latest budget is either empty or the same as application budget we reset default value
  if (!latestUpdateIsASourceOfDefaultValue) {
    Object.entries({
      ...toRet.revenuesLines,
      ...toRet.expensesLines
    }).forEach(([lineKey, lineData]) => {
      const { applicationBudget } = lineData
      const prefix =
        lineKey in toRet.revenuesLines ? 'revenuesLines' : 'expensesLines'
      const value = applicationBudget.value
      const comment = Array.isArray(applicationBudget.comments)
        ? applicationBudget.comments.join('\n')
        : applicationBudget.comments
      _.set(toRet, `${prefix}.${lineKey}.defaultValue`, {
        value,
        comment
      })
    })
  }

  return toRet
}

export const formBudgetUpdateSavePromise = ({ value }) => {
  const updatesToUpdate = []
  const updatesToCreate = []
  const dataArray = [
    ...Object.values(value.expensesLines),
    ...Object.values(value.revenuesLines)
  ]
  dataArray.forEach((dataObj) => {
    const { budgetUpdateChange, budgetUpdateActual = {}, categoryId } = dataObj
    if (budgetUpdateChange && budgetUpdateChange.change === '') {
      budgetUpdateChange.change = null
    }
    if (budgetUpdateActual && budgetUpdateActual.actual === '') {
      budgetUpdateActual.actual = null
    }
    const toUpdate = { ...budgetUpdateActual, ...budgetUpdateChange }
    const reportId = value.report.id
    if (toUpdate.id) {
      delete toUpdate.granteeReport
      updatesToUpdate.push(toUpdate)
    } else {
      updatesToCreate.push({
        ...toUpdate,
        budgetCategory: categoryId,
        granteeReport: reportId
      })
    }
  })
  const promises = [
    saveReportParsed({
      ...value.report
    })
  ]


  if (updatesToCreate.length > 0) {
    promises.push(createBudgetUpdateParsed(updatesToCreate))
  }
  if (updatesToUpdate.length > 0) {
    promises.push(updateBudgetUpdateParsed(updatesToUpdate))
  }

  return { promise: Promise.all(promises) }
}

// Define the schema for each inner object of expensesLines and revenuesLines objects
const innerObjectSchema = Yup.object().shape({
  budgetUpdateChange: Yup.object().shape({
    change: Yup.number().nullable().required(requiredTrans),
  }),
  budgetUpdateActual: Yup.object().shape({
    actual: Yup.number().nullable().required(requiredTrans),
  })
})

const validationSchema = Yup.lazy((value) =>
  Yup.object(
    Object.keys(value || {}).reduce((acc, key) => {
      acc[key] = innerObjectSchema
      return acc
    }, {})
  )
)

export const formBudgetUpdateValidation = (item) => {
  return Yup.object({
    expensesLines: validationSchema,
    revenuesLines: validationSchema
  }).test(
    'validate-budget-change',
    'If report.hasBudgetChanged is "Yes", the sum of all expensesLines[key].budgetUpdateChange.change must be equal to the sum of all revenuesLines[key].budgetUpdateChange.change',
    function (values) {
      const { report, expensesLines, revenuesLines } = values || {}
      if (report?.hasBudgetChanged === 'Yes') {
        const totalExpensesLinesChange = Object.keys(expensesLines || {}).reduce(
          (sum, key) => sum + (expensesLines[key]?.budgetUpdateChange?.change || 0),
          0
        )
        const totalRevenuesLinesChange = Object.keys(revenuesLines || {}).reduce(
          (sum, key) => sum + (revenuesLines[key]?.budgetUpdateChange?.change || 0),
          0
        )
        if (totalExpensesLinesChange !== totalRevenuesLinesChange) {
          return new Yup.ValidationError(
            <Trans>BUDGET_UPDATE_UNEQUAL_EXPENSES_REVENUES_SUMS</Trans>,
            null,
            this.path + '.report.hasBudgetChanged'
          )
        }
      }
      return true // Pass validation if hasBudgetChanged is not "Yes"
    }
  ).test(
    'validate-change-comments',
    'Comments are required when change is different from budget.',
    function (value) {
      const { path, createError } = this;
      const errors = [];
      
      for (const key of ['expensesLines', 'revenuesLines']) {
        for (const innerKey of Object.keys(value[key] || {})) {
          const budget = value[key]?.[innerKey]?.applicationBudget?.value;
          const change = value[key]?.[innerKey]?.budgetUpdateChange?.change;
          const comments = value[key]?.[innerKey]?.budgetUpdateChange?.changeDetails;

          if (budget !== change && (!comments || comments.trim() === '')) {
            errors.push(createError({
              path: this.path + `[${key}][${innerKey}].budgetUpdateChange.changeDetails`,
              message: requiredTrans
            }));
          }
        }
      }      
      return errors.length ? new Yup.ValidationError(errors) : true;
    }
  ).test(
    'validate-actual-comments',
    'Comments are required when actual is greater than 0 and the line is not "Transformation Centre Grant".',
    function (value) {
      const { path, createError } = this;
      const errors = [];
      
      for (const key of ['expensesLines', 'revenuesLines']) {
        for (const innerKey of Object.keys(value[key] || {})) {
          const actual = value[key]?.[innerKey]?.budgetUpdateActual?.actual;
          const comments = value[key]?.[innerKey]?.budgetUpdateActual?.actualComments;
          
          if (actual > 0 && innerKey !== 'Transformation Centre Grant' && (!comments || comments.trim() === '')) {
            const path = this.path + `[${key}][${innerKey}].budgetUpdateActual.actualComments`;
            errors.push(createError({
              path,
              message: requiredTrans
            }));
          }
        }
      }
      
      return errors.length ? new Yup.ValidationError(errors) : true;
    }
  )
}

export const borderString = '1px solid rgba(224, 224, 224, 1)'

export const FormBudgetUpdate = ({
  id,
  langVersion,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  i18n,
  disabled,
  helpText,
  ...props
}) => {
  const [field, meta, helpers] = useField(id)
  const { value } = field
  const { setFieldValue } = useFormikContext()
  const setValue = (value) => {
    setFieldValue(id, value)
  }
  const { error } = helpers

  const [resetConfirmationOpen, setResetConfirmationOpen] = useState(false)
  const editable =
    value.report &&
    (value.report.status === 'Requested' ||
      value.report.status === 'More info required')

  const checkIfBudgetWasChanged = () =>
    Object.values({ ...value.expensesLines, ...value.revenuesLines }).some(
      ({ applicationBudget, budgetUpdateChange, latestUpdate, defaultValue }, index) => {
        return defaultValue.value !== budgetUpdateChange.change || defaultValue.comment !== budgetUpdateChange.changeDetails
      }
    )

  const isLatestUpdate = Boolean(value.latestReport)

  return (
    <>
      <Dialog fullWidth open={resetConfirmationOpen}>
        <DialogTitleWithIconClose
          label={<Trans>BUDGET_UPDATE_CONFIRM_RESET_DIALOG_TITLE</Trans>}
          handleClose={() => {
            setResetConfirmationOpen(false)
          }}
        />

        <DialogContent>
          <Grid container justifyContent='space-around'>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => {
                setResetConfirmationOpen(false)
              }}
            >
              <Trans>No</Trans>
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={(e) => {
                const toSet = { ...value }
                toSet.report.hasBudgetChanged = 'No'
                const keys = ['expensesLines', 'revenuesLines']
                keys.forEach((key) => {
                  Object.entries(toSet[key]).forEach(([lineKey, value]) => {
                    toSet[key][lineKey].budgetUpdateChange = {
                      ...toSet[key][lineKey].budgetUpdateChange,
                      change: toSet[key][lineKey].defaultValue.value,
                      changeDetails: toSet[key][lineKey].defaultValue.comment
                    }
                  })
                })

                setValue(toSet)
                setResetConfirmationOpen(false)
              }}
            >
              <Trans>Yes</Trans>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <TableContainer>
        <div style={{ textAlign: 'center' }}>
          {title && <h3>{title}</h3>}
          {helpText && <FormHelptext helpText={helpText} />}
        </div>

        <Table style={{ marginTop: 8, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow style={{ wordBreak: 'break-word' }}>
              <TableCell
                style={{
                  padding: 24,
                  verticalAlign: 'top',
                  fontWeight: 'bold',
                  fontSize: 18,
                  border: borderString
                }}
              >
                <Trans>BUDGET_UPDATE_ORIGINAL_BUDGET</Trans>
              </TableCell>
              {isLatestUpdate && (
                <TableCell
                  style={{
                    padding: 24,
                    verticalAlign: 'top',
                    fontWeight: 'bold',
                    fontSize: 18,
                    border: borderString
                  }}
                >
                  <Trans>BUDGET_UPDATE_LATEST_UPDATE</Trans>
                </TableCell>
              )}
              <TableCell
                style={{
                  padding: 24,
                  verticalAlign: 'top',
                  fontWeight: 'bold',
                  fontSize: 18,
                  border: borderString
                }}
              >
                <Trans>BUDGET_UPDATE_HAS_YOUR_BUDGET_CHANGE</Trans>
                <div>
                  <FormControl component='fieldset' error={error?.report?.hasBudgetChanged}>
                    <RadioGroup
                      name={id + '.report.hasBudgetChanged'}
                      margin='dense'
                      row
                      value={value.report?.hasBudgetChanged}
                      onChange={(e) => {
                        const toSet = { ...value }
                        if (e.target.value === 'No') {
                          if (checkIfBudgetWasChanged()) {
                            setResetConfirmationOpen(true)
                          } else {
                            toSet.report.hasBudgetChanged = 'No'
                            const keys = ['expensesLines', 'revenuesLines']
                            keys.forEach((key) => {
                              Object.entries(toSet[key]).forEach(
                                ([lineKey, value]) => {
                                  toSet[key][lineKey].budgetUpdateChange = {
                                    ...toSet[key][lineKey].budgetUpdateChange,
                                    //changeDetails: ''
                                  }
                                }
                              )
                            })
                            setValue(toSet, true)
                          }
                        } else {
                          toSet.report.hasBudgetChanged = 'Yes'
                          setValue(toSet, false)
                        }
                      }}
                    >
                      <FormControlLabel
                        value='Yes'
                        disabled={disabled}
                        control={<Radio />}
                        label={<Trans>Yes</Trans>}
                      />
                      <FormControlLabel
                        value='No'
                        disabled={disabled}
                        control={<Radio />}
                        label={<Trans>No</Trans>}
                      />
                    </RadioGroup>
                    <FormHelperText>{error?.report?.hasBudgetChanged}</FormHelperText>
                  </FormControl>
                </div>

                {value.report?.hasBudgetChanged === 'Yes' && (
                  <div style={{ fontSize: 12 }}>
                    <Trans>BUDGET_UPDATE_PLEASE_INDICATE_CHANGE</Trans>
                  </div>
                )}
              </TableCell>
              <TableCell
                style={{
                  padding: 24,
                  verticalAlign: 'top',
                  fontWeight: 'bold',
                  fontSize: 18,
                  border: borderString
                }}
              >
                <Trans>BUDGET_UPDATE_ACTUAL_EXPENSES_REVENUES</Trans>
                <div style={{ fontSize: 12 }}>
                  <Trans>BUDGET_UPDATE_ENTER_ZERO_IF_NO_EXPENSE_REVENUE_COLUMN_HELPTEXT</Trans>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  padding: 16,
                  border: 'none',
                  borderLeft: borderString,
                  borderRight: borderString
                }}
              >
                <Trans>BUDGET_UPDATE_REVENUES</Trans>
              </TableCell>
              {[...Array(isLatestUpdate ? 3 : 2).keys()].map((index) => {
                return (
                  <TableCell
                    key={index}
                    style={{
                      border: 'none',
                      borderLeft: borderString,
                      borderRight: borderString
                    }}
                  />
                )
              })}
            </TableRow>
            {value.revenuesLines &&
              Object.keys(value.revenuesLines)
                .sort((a, b) => BUDGET_REVENUES_LINES_IN_ORDER.indexOf(a) - BUDGET_REVENUES_LINES_IN_ORDER.indexOf(b))
                .map((key, index) => {
                  const dataObj = value.revenuesLines[key]

                  return (
                    <BudgetUpdateRow
                      {...dataObj}
                      langVersion={langVersion}
                      lineKey={key}
                      key={key}
                      index={index}
                      disabled={disabled}
                      changeDisabled={value.report.hasBudgetChanged === 'No'}
                      id={id}
                      muBag={muBag}
                      useMultiuser={useMultiuser}
                      isExpense={false}
                      isLatestUpdate={isLatestUpdate}
                      editable={editable}
                    />
                  )
                })}
            <TableRow>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  padding: 16,
                  border: 'none',
                  borderLeft: borderString,
                  borderRight: borderString
                }}
              >
                <Trans>BUDGET_UPDATE_EXPENSES</Trans>
              </TableCell>
              {[...Array(isLatestUpdate ? 3 : 2).keys()].map((index) => {
                return (
                  <TableCell
                    key={index}
                    style={{
                      border: 'none',
                      borderLeft: borderString,
                      borderRight: borderString
                    }}
                  />
                )
              })}
            </TableRow>
            {value.expensesLines &&
              Object.keys(value.expensesLines)
                .sort((a, b) => BUDGET_EXPENSES_LINES_IN_ORDER.indexOf(a) - BUDGET_EXPENSES_LINES_IN_ORDER.indexOf(b))
                .map((key, index) => {
                  const dataObj = value.expensesLines[key]

                  return (
                    <BudgetUpdateRow
                      {...dataObj}
                      lineKey={key}
                      langVersion={langVersion}
                      key={key}
                      index={index}
                      disabled={disabled}
                      changeDisabled={value.report.hasBudgetChanged === 'No'}
                      id={id}
                      muBag={muBag}
                      useMultiuser={useMultiuser}
                      isExpense
                      isLatestUpdate={isLatestUpdate}
                      editable={editable}
                    />
                  )
                })}

            <TableRow>
              <TableCell
                style={{
                  borderLeft: borderString
                }}
              >
                <Grid container>
                  <Grid item xs />
                  <Grid item xs>
                    <Sum
                      revenuesLines={value.revenuesLines}
                      expensesLines={value.expensesLines}
                      budgetKey='applicationBudget'
                      subKey='value'
                      langVersion={langVersion}
                    />
                  </Grid>
                </Grid>
              </TableCell>

              {isLatestUpdate && (
                <TableCell
                  style={{
                    borderLeft: borderString,
                    borderRight: borderString
                  }}
                >
                  <Sum
                    revenuesLines={value.revenuesLines}
                    expensesLines={value.expensesLines}
                    budgetKey='latestUpdate'
                    subKey='change'
                    langVersion={langVersion}
                  />
                </TableCell>
              )}

              <TableCell
                style={{
                  borderLeft: borderString,
                  borderRight: borderString
                }}
              >
                <Sum
                  revenuesLines={value.revenuesLines}
                  expensesLines={value.expensesLines}
                  budgetKey='budgetUpdateChange'
                  subKey='change'
                  langVersion={langVersion}
                />
              </TableCell>

              <TableCell
                style={{
                  borderLeft: borderString,
                  borderRight: borderString
                }}
              >
                <Sum
                  revenuesLines={value.revenuesLines}
                  expensesLines={value.expensesLines}
                  budgetKey='budgetUpdateActual'
                  subKey='actual'
                  langVersion={langVersion}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const Sum = ({
  revenuesLines,
  expensesLines,
  budgetKey,
  subKey,
  langVersion,
  printable = false
}) => {
  let revenuesSum = 0
  let expensesSum = 0

  if (revenuesLines) {
    Object.entries(revenuesLines).forEach(([key, obj]) => {
      const dataObj = obj[budgetKey]
      const value = dataObj?.[subKey]
      if (value) {
        revenuesSum += +value
      }
    })
  }
  if (expensesLines) {
    Object.entries(expensesLines).forEach(([key, obj]) => {
      const dataObj = obj[budgetKey]
      const value = dataObj?.[subKey]
      if (value) {
        expensesSum += +value
      }
    })
  }

  return (
    <div style={{ padding: 16 }}>
      <Grid
        container
        justifyContent='space-between'
        style={{ paddingBottom: 8, paddingTop: 8 }}
      >
        <Grid
          item
          style={{ fontWeight: 'bold' }}
          className={printable ? 'form-print-subtitle' : ''}
        >
          <Trans>SUM_OF_REVENUES</Trans>
        </Grid>
        <Grid item>{currencyFormatedString(revenuesSum, langVersion)}</Grid>
      </Grid>
      <Divider />
      <Grid
        container
        justifyContent='space-between'
        style={{ paddingBottom: 8, paddingTop: 8 }}
      >
        <Grid
          item
          style={{ fontWeight: 'bold' }}
          className={printable ? 'form-print-subtitle' : ''}
        >
          <Trans>SUM_OF_EXPENSES</Trans>
        </Grid>
        <Grid item>{currencyFormatedString(expensesSum, langVersion)}</Grid>
      </Grid>
      <Divider />
      <Grid
        container
        justifyContent='space-between'
        style={{ paddingBottom: 8, paddingTop: 8 }}
      >
        <Grid
          item
          style={{ fontWeight: 'bold' }}
          className={printable ? 'form-print-subtitle' : ''}
        >
          <Trans>TOTAL_REVENUES_AND_EXPENSES</Trans>
        </Grid>
        <Grid item>
          {currencyFormatedString(revenuesSum - expensesSum, langVersion)}
        </Grid>
      </Grid>
    </div>
  )
}

export const BudgetUpdateRow = ({
  applicationBudget,
  budgetUpdateChange,
  budgetUpdateActual,
  latestUpdate,
  lineKey,
  langVersion,
  disabled,
  changeDisabled,
  id,
  muBag,
  useMultiuser,
  isLatestUpdate,
  isExpense,
  editable,
  printable = false
}) => {
  const formikKey = isExpense ? 'expensesLines' : 'revenuesLines'

  return (
    <TableRow>
      <TableCell
        style={{
          borderLeft: borderString,
          borderRight: borderString,
          verticalAlign: 'top'
        }}
      >
        <Grid container direction='row' wrap='nowrap' alignItems='center'>
          <Grid
            item
            xs
            style={{
              // textAlign: 'center',
              padding: 16,
              fontWeight: 'bold',
              wordBreak: 'break-word',
              hyphens: 'auto'
            }}
            className={printable ? 'form-print-subtitle' : ''}
          >
            <Trans id={lineKey} />
          </Grid>
          <Grid item xs style={{ padding: 16 }}>
            <div>
              <Trans>Amount</Trans>
            </div>
            <Card style={{ padding: 16, minHeight: 52 }} elevation={6}>
              {currencyFormatedString(applicationBudget.value, langVersion)}
            </Card>

            <div style={{ marginTop: 15 }}>
              <Trans>Comment</Trans>
            </div>
            <Card style={{ padding: 16 }} elevation={6}>
              {Array.isArray(applicationBudget.comments)
                ? applicationBudget.comments.join('\n')
                : ''}
            </Card>
          </Grid>
        </Grid>
      </TableCell>
      {isLatestUpdate && (
        <TableCell
          style={{
            borderLeft: borderString,
            borderRight: borderString,
            verticalAlign: 'top'
          }}
        >
          <Grid item xs style={{ padding: 16 }}>
            <div>
              <Trans>Amount</Trans>
            </div>
            <Card style={{ padding: 16 }} elevation={6}>
              {currencyFormatedString(latestUpdate?.change, langVersion)}
            </Card>
            <div style={{ marginTop: 15 }}>
              <Trans>Comment</Trans>
            </div>
            <Card style={{ padding: 16 }} elevation={6}>
              {latestUpdate?.changeDetails}
            </Card>
          </Grid>
        </TableCell>
      )}
      <TableCell
        style={{
          borderLeft: borderString,
          borderRight: borderString,
          verticalAlign: 'top'
        }}
      >
        {changeDisabled || !editable
          ? (
            <Grid direction='column' container item xs style={{ padding: 16 }}>
              <div>
                <Trans>Amount</Trans>
              </div>
              <Card style={{ padding: 16, minHeight: 52 }} elevation={6}>
                {currencyFormatedString(budgetUpdateChange?.change, langVersion)}
              </Card>
              <div style={{ marginTop: 15 }}>
                <Trans>Comment</Trans>
              </div>
              <Card style={{ padding: 16 }} elevation={6}>
                {budgetUpdateChange?.changeDetails}
              </Card>
            </Grid>)
          : (
            <Grid container item xs direction='column' style={{ padding: 16 }}>
              <div>
                <Trans>Amount</Trans>
              </div>
              <MUTextField
                id={id + `.${formikKey}.${lineKey}.budgetUpdateChange.change`}
                type='number'
                multiline
                currency
                useMultiuser={useMultiuser}
                muBag={muBag}
                displayFieldHistoryIcon
                disabled={disabled || changeDisabled}
                required
                langVersion={langVersion}
                additionalHelperText={<Trans>BUDGET_UPDATE_ENTER_ZERO_IF_NO_EXPENSE_REVENUE</Trans>}
                showHelperText={!printable}
              />

              <div style={{ marginTop: 16 }}>
                <Trans>Comment</Trans>
              </div>
              <MUTextField
                limit={500}
                id={
                  id + `.${formikKey}.${lineKey}.budgetUpdateChange.changeDetails`
                }
                multiline
                useMultiuser={useMultiuser}
                muBag={muBag}
                displayFieldHistoryIcon
                disabled={disabled || changeDisabled}
                required={Boolean(
                  budgetUpdateChange &&
                    budgetUpdateChange.change &&
                    +budgetUpdateChange.change !== +applicationBudget.value
                )}
                showHelperText={!printable}
              />
            </Grid>
            )}
      </TableCell>
      <TableCell
        style={{
          borderLeft: borderString,
          borderRight: borderString,
          verticalAlign: 'top'
        }}
      >
        <Grid container item xs direction='column' style={{ padding: 16 }}>
          {!editable || lineKey === budgetCentreGrantKey
            ? (
              <Grid item xs>
                <div>
                  <Trans>Amount</Trans>
                </div>
                <Card style={{ padding: 16, minHeight: 52 }} elevation={6}>
                  {currencyFormatedString(
                    budgetUpdateActual?.actual,
                    langVersion
                  )}
                </Card>
                {budgetUpdateActual?.changeDetails && (
                  <>
                    <div style={{ marginTop: 15 }}>
                      <Trans>Comment</Trans>
                    </div>
                    <Card style={{ padding: 16 }} elevation={6}>
                      {budgetUpdateActual?.changeDetails}
                    </Card>
                  </>
                )}
              </Grid>
              )
            : (
              <Grid item xs>
                <div>
                  <Trans>Amount</Trans>
                </div>
                <MUTextField
                  id={id + `.${formikKey}.${lineKey}.budgetUpdateActual.actual`}
                  type='number'
                  currency
                  multiline
                  useMultiuser={useMultiuser}
                  muBag={muBag}
                  displayFieldHistoryIcon
                  disabled={disabled}
                  required
                  langVersion={langVersion}
                  additionalHelperText={<Trans>BUDGET_UPDATE_ENTER_ZERO_IF_NO_EXPENSE_REVENUE</Trans>}
                  showHelperText={!printable}
                />

                <div style={{ marginTop: 16 }}>
                  <Trans>Comment</Trans>
                </div>
                <MUTextField
                  id={
                    id +
                    `.${formikKey}.${lineKey}.budgetUpdateActual.actualComments`
                  }
                  limit={500}
                  multiline
                  useMultiuser={useMultiuser}
                  muBag={muBag}
                  displayFieldHistoryIcon
                  disabled={disabled}
                  required={Boolean(
                    budgetUpdateActual && +budgetUpdateActual.actual
                  )}
                  showHelperText={!printable}
                />
              </Grid>
              )}
        </Grid>
      </TableCell>
    </TableRow>
  )
}
