import { Trans } from '@lingui/macro';
import { MenuItem, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getPicklistConditionError, getTranslatablePicklistLabel, isPicklistValid, noValuePicklistLabel } from "../../editor/ConditionalElementEditor";
import { FieldType } from "../table/ConfigureTableFilters";

/** Represents a selectable option in the picklist. */
interface SelectableOption {
  label: string;
  value: string;
  apiValue: string;
}

/** Represents the selected element structure. */
interface SelectedElement {
  picklistValues?: Array<{
    label?: string
    apiValue?: string
    value?: string
  }>;
  sfField?: string
}

/** Represents the condition item structure. */
interface ConditionItem {
  parameter?: string
  condition?: any
}

/** Props for the ConditionPicklistField component. */
interface ConditionPicklistFieldProps {
  item: ConditionItem;
  onChange: (e: any) => void
  avaliableElementsMap?: Record<string, any>
  selectedElement?: SelectedElement | FieldType
}

/**
 * A component for rendering a dropdown (picklist) field based on available options.
 *
 * @param {ConditionPicklistFieldProps} props - The properties of the component.
 * @returns {JSX.Element} A dropdown field for managing condition picklist options.
 */
export default function ConditionPicklistField ({
  item,
  onChange,
  avaliableElementsMap,
  selectedElement
}: ConditionPicklistFieldProps) {
  const userLanguage = useSelector((state: { user: { language: string } }) => state.user.language);
  const isPicklistValidCondition = avaliableElementsMap
    ? isPicklistValid({ condition: item, avaliableElementsMap })
    : true

  let selectableOptions: SelectableOption[] = []
  if (selectedElement && selectedElement.picklistValues) {
    selectableOptions = selectedElement.picklistValues.map((option, index) => {
     return { label:
        option.label ||
        option.apiValue ||
        getTranslatablePicklistLabel(option, userLanguage),
      value: option.value || option.apiValue || noValuePicklistLabel + index,
      apiValue: option?.apiValue || option.value || selectedElement?.sfField
        ? `sfield: ${selectedElement.sfField}`
        : ''}
    })
  }

  return (
    <TextField
      disabled={
        (selectableOptions?.length === 0 &&
          isPicklistValidCondition) ||
        !item.condition
      }
      variant='outlined'
      select
      fullWidth
      value={item.parameter || ''}
      onChange={(e) => onChange(e)}
      error={!isPicklistValidCondition}
      helperText={isPicklistValidCondition
        ? ''
        : getPicklistConditionError(item, avaliableElementsMap)}
    >
      {selectableOptions.map((option, index) => {
        const noApiValue =
          !option.apiValue || option.apiValue === ''
        return (
          <MenuItem
            value={option.value}
            key={index}
            //noApiValue={noApiValue}
            style={{ opacity: noApiValue ? 0.5 : 1 }}
          >
            {option.label} &nbsp; {noApiValue &&
              <span>(<Trans>FORM_CONDITION_PICKLIST_OPTION_NO_API_VALUE</Trans>)</span>}
          </MenuItem>
        )
      })}
    </TextField>
  )
}