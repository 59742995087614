import { Html } from 'react-pdf-html'
import { HtmlProps } from 'react-pdf-html/dist/Html'

/** ImprovedHtml component that fixes bugs of the PDF page display
 * @param children - The content to render
 * @param collapse - Whether to collapse the content
 * @param style - The style to apply to the content
 * @param rest - The rest of the props
 */
export const ImprovedHtml = ({
  children,
  collapse,
  style,
  ...rest
}: HtmlProps): JSX.Element => {

  collapse = collapse === undefined ? true : collapse
  const html = String(children)
    .replace(/\s*style="[^"]*"/g, '')
    .replaceAll('\n', '')
    //.replaceAll('</p>', '</p><br><br>')
  return (
    <>
      <Html
        collapse={collapse}
        style={style}
        {...rest}
      >
        {html}
      </Html>
    </>
  )
}
