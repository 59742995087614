import { formConditions } from "../../editor/ConditionalElementEditor"

/** Represents a single filter used in the table. */
interface TableFilter {
  /** The type of the filter, corresponding to a rule in `formConditions`. */
  type: string
  /** The condition to evaluate (e.g., "equals", "greaterThan"). */
  condition: string
  /** The parameter used by the condition (e.g., a value to compare). */
  parameter: any
  /** The key in the collection object to compare against the filter. */
  childObject: string
}

/** Represents a column in the table. */
interface TableColumn {
  field: {
    name: string
    type: string
  };
  label: string
  translate?: boolean
}

/**
 * Checks if a table filter is met based on the provided value to compare.
 *
 * @param {TableFilter} filter - The filter object containing type, condition, and parameter.
 * @param {any} valueToCompare - The value from the collection to compare against the filter.
 * @returns {boolean} `true` if the filter condition is met, otherwise `false`.
 */
export const isTableFilterMet = (
  filter: TableFilter,
  valueToCompare: any
): boolean => {
  const { type, condition, parameter } = filter
  const testRule = formConditions?.[type]?.conditions[condition].rule
  return testRule(parameter, valueToCompare)
}

/**
 * Filters a collection based on the specified table filters.
 *
 * @param {any[]} collection - The collection of objects to filter.
 * @param {TableFilter[]} [tableFilters=[]] - The filters to apply on the collection.
 * @returns {any[]} A filtered collection containing only the objects that satisfy all filters.
 */
export const getFilteredCollection = (
  collection: any[],
  tableFilters: TableFilter[] = []
): any[] => {  
  return collection.filter(obj => {
    const allFiltersValid = tableFilters.every(filter => {
      const { childObject } = filter
      const valueToCompare = obj[childObject]
      return isTableFilterMet(filter, valueToCompare)
    })
    if (allFiltersValid) {
      return collection
    }
    return null
  })
}