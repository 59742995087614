import { TextField } from "@material-ui/core";
import { NumberFormatCustom } from "app/views/page-layouts/FormElement"

/** Defines the supported rule types for the component. */
type RulesType = "numericSlider" | "textInputNumeric" | "budget" | string;

interface ConditionTextParameterFieldProps {
  /** Callback invoked when the field's value changes. */
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  /** The current value of the text parameter. */
  value?: string
  /** The type of the rule, determining input customization behavior. */
  rulesType?: RulesType
}

/**
 * A component for rendering a customizable text parameter field.
 *
 * @param {ConditionTextParameterFieldProps} props - The properties of the component.
 * @returns {JSX.Element} A text field with optional numeric formatting.
 */
export default function ConditionTextParameterField ({
  onChange,
  value,
  rulesType
}: ConditionTextParameterFieldProps): JSX.Element {
  return (
    <TextField
      variant='outlined'
      fullWidth
      InputProps={
        [
          'numericSlider',
          'textInputNumeric',
          'budget'
        ].includes(rulesType ?? '') ? {
          inputComponent: NumberFormatCustom
        } : undefined
      }
      value={value || ''}
      onChange={(e) => onChange(e)}
    />
  )
}