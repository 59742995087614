import { t } from '@lingui/macro'
import { Text, View } from '@react-pdf/renderer'
import { ImprovedHtml } from 'app/views/common-components/ImprovedHtml'
import { myI18n } from 'translation/I18nConnectedProvider'
import { isPureBooleanTS } from 'utilsTS'
import { pdfDefaultFontSize } from '../common/Common'
import {
    defaultFormPdfFontFamily,
    inuktitutFormPdfFontFamily
} from './FormPdf'
import { purgeInvalidPDFSignFromString } from './formPdfUtils'

interface FormElementTitlePDFProps {
  index: number
  parseAsHtml?: boolean
  parseHelpTextAsHtml?: boolean
  required?: boolean
  showNumering?: boolean
  title: string
  language: string
  elementType: string
  formViewType?: 'pdf' | 'fillable-pdf'
  helpText?: React.ReactNode
}

export const FormElementTitlePDF = ({
  index,
  parseAsHtml,
  parseHelpTextAsHtml,
  required,
  helpText,
  showNumering,
  elementType,
  language,
  title,
  formViewType = 'pdf'
}: FormElementTitlePDFProps): JSX.Element => {
  if (elementType === 'uploadFiles' && formViewType === 'fillable-pdf') {
    return (
      <Text
        style={{
          fontSize: 10,
          fontWeight: 'bold',
          marginBottom: 8,
          fontFamily: language === 'iu-cans'
            ? inuktitutFormPdfFontFamily
            : defaultFormPdfFontFamily
        }}
      >
        {isPureBooleanTS(showNumering) && String(index) + '. '}
        {myI18n._(t`FORM_FILLABLE_ATTACH_DOCUMENT`)}
      </Text>
    )
  }

  return (
    <>
      <Text
        style={{
          fontSize: 10,
          fontWeight: 'bold',
          marginBottom: 8,
          fontFamily:
            language === 'iu-cans'
              ? inuktitutFormPdfFontFamily
              : defaultFormPdfFontFamily
        }}
      >
        {isPureBooleanTS(showNumering) && String(index) + '. '}
        {parseAsHtml
          ? (
            <ImprovedHtml
              collapse={false}
              style={{
                fontSize: pdfDefaultFontSize,
                fontFamily:
        language === 'iu-cans'
          ? inuktitutFormPdfFontFamily
          : defaultFormPdfFontFamily
              }}
            >
              {title}
            </ImprovedHtml>
            )
          : (
              purgeInvalidPDFSignFromString(title)
            )}
        {required && ` (${myI18n._(t`Required`)})`}
      </Text>
      {helpText
        ? (
          <View>
            {parseHelpTextAsHtml
              ? (
                <ImprovedHtml
                  collapse={false}
                  stylesheet={{
                    '*': {
                      fontSize: pdfDefaultFontSize - 2,
                      margin: 0,
                      padding: 0,
                      fontStyle: 'italic',
                      fontFamily:
                    language === 'iu-cans'
                      ? inuktitutFormPdfFontFamily
                      : defaultFormPdfFontFamily
                    },
                    p: {
                      marginBottom: 8
                    },
                    ul: {
                      padding: 0
                    },
                    li: {
                      padding: 0
                    }
                  }}
                >
                  {String(helpText)}
                </ImprovedHtml>
                )
              : <Text
                  style={{
                    fontSize: pdfDefaultFontSize - 2,
                    fontStyle: 'italic',
                    marginBottom: 8,
                    fontFamily:
                language === 'iu-cans'
                  ? inuktitutFormPdfFontFamily
                  : defaultFormPdfFontFamily
                  }}
                >
                {helpText}
              </Text>}
          </View>
          )
        : (
          <View />
          )}
    </>
  )
}
