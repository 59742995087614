import { Trans, t } from '@lingui/macro'
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import { dateFormat } from 'app/appSettings'
import { deleteBudgetLines } from 'app/services/sfAuth/sfData/sfOpportunity'
import { globalValues } from 'app/utils/GlobalValues'
import { CurrencyFormat, CurrencyFormated } from 'app/views/common/Formats'
import { FormLabel } from 'app/views/common/labels/FormLabel'
import { FormHelptext } from 'app/views/forms/common/FormHelpText'
import { useField, useFormikContext } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { languages, myI18n } from 'translation/I18nConnectedProvider'
import * as Yup from 'yup'
import { parseFormLabelText } from '../../../common/Common'
import { requiredTrans } from '../../../formTranslations'
import MUTextField from '../../../multiuser/components/MUTextField'
import { endEditingField } from '../../../multiuser/grpcMultiuserEdit'
import { FormBudgetError } from './FormBudgetError'
import currency from 'currency.js'

/**
 * Form element which renders a table containing project budget years, and expenses and revenues that can be filled for every budget year. Budget lines filled are saved as
 * Salesforce FGM_Portal__Grantee_Budget_Line_Item__c object, with connected Opportunity as parent.
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {JSX.Element}
 * @param  {Object} typeProps - Element specific properties that can be configured in form editor.
 * @param  {FormElementReference}  typeProps.requestedAmountId Deprecated. Reference to another element of Numeric Inpput type. The value of referenced elements will be used to set the target value of the whole budget.
 * @param  {FormElementReference}  typeProps.requestedAmountIds Required. Array of references to other elements of Numeric Inpput type. The values of referenced elements will be used to set the target value of the whole budget.
 * @param  {FormElementReference}  typeProps.milestonesId Required. Reference to another element of Milestones type. The value of referenced elements will be used to determine budget years.
 * @param  {Object[]} typeProps.revenuesLines Array of options defined in the form editor which will determine what budget lines for revenues section will be rendered.
 * @param  {Object[]} typeProps.expensesLines Array of options defined in the form editor which will determine what budget lines for expenses section will be rendered.
 * @param  {number}  [typeProps.commentsRows=1] Number of rows which comments text field should occupy.
 * @param  {boolean}  [typeProps.commentsExpandable=false] Defines if comments text field can expand further below if new line is inserted.
 * @param  {string}  [typeProps.revenuesHelpText] Help text that will be rendered above revenues section.
 * @param  {string}  [typeProps.expensesHelpText] Help text that will be rendered above expenses section.
 */
export const FormBudget = ({
  id,
  langVersion,
  useMultiuser,
  muBag,
  title,
  connectedObject,
  editMode,
  reloadLastModifiedDates,
  typeProps,
  i18n,
  elementsMap,
  disabledIds = [],
  ...props
}) => {
  let disabled = props.disabled
  let warning
  const { values, setFieldValue } = useFormikContext()
  const [field, meta] = useField(id)
  const error = meta.touched && meta.error && (
    <div style={{ margin: 20 }}>
      <Typography style={{ fontSize: '14px', color: 'red' }}>
        {meta.error}
      </Typography>
    </div>
  )
  const value = values[id] || {}
  const { revenues = {}, expenses = {} } = value
  const classes = useStyles()
  const {
    requestedAmountId,
    revenuesLines = [],
    expensesLines = [],
    commentsRows,
    commentsExpandable,
    revenuesHelpText,
    expensesHelpText
  } = typeProps

  let requestedAmountIds = typeProps.requestedAmountIds || []

  // update requestedAmountIds if requestedAmountId is set and it is not in the list
  requestedAmountIds =
    !requestedAmountId ||
    (requestedAmountId && requestedAmountIds.includes(requestedAmountId))
      ? [...requestedAmountIds]
      : [...requestedAmountIds, requestedAmountId]

  const amountRequested = requestedAmountIds.reduce((acc, id) => {
    const value = Number(values[id] || 0)
    return acc + value
  }, 0)

  let projectStartDate, projectEndDate
  if (elementsMap) {
    Object.values(elementsMap).some((obj) => {
      if (obj.elementType === 'milestones' && !disabledIds.includes(obj.id)) {
        const mValue = values[obj.id]
        if (mValue && Array.isArray(mValue)) {
          const onlyUsed = mValue.filter((m) => m.isDisplayed)
          projectStartDate =
            onlyUsed[0]?.startDate &&
            moment.utc(onlyUsed[0].startDate).format(dateFormat)
          projectEndDate =
            onlyUsed[onlyUsed.length - 1]?.endDate &&
            moment
              .utc(onlyUsed[onlyUsed.length - 1].endDate)
              .format(dateFormat)
        }
        return true
      }
      return false
    })
  }

  const startDate = moment(projectStartDate).utc().year()
  const endDate = moment(projectEndDate).utc().year()

  const invalid = Boolean(
    !connectedObject ||
      !connectedObject.Id ||
      connectedObject.attributes.type !== 'Opportunity'
  )

  useEffect(() => {
    const toSet = _.cloneDeep(value.revenues)
    let sum = 0
    for (const yearKey in toSet) {
      const existingValue =
        _.get(toSet[yearKey], `${budgetCentreGrantKey}.value`) || 0
      sum += existingValue
    }

    if (sum !== amountRequested) {
      const baseValue = Math.floor(
        +amountRequested / Object.keys(toSet).length
      )
      let remainder = amountRequested % Object.keys(toSet).length

      Object.keys(toSet).forEach((year, index) => {
        if (!toSet[year][budgetCentreGrantKey]) {
          toSet[year][budgetCentreGrantKey] = {}
        }
        toSet[year][budgetCentreGrantKey].value = baseValue
        if (remainder) {
          toSet[year][budgetCentreGrantKey].value++
          remainder--
        }
      })

      setFieldValue(id + '.revenues', toSet)
    }
  }, [amountRequested])

  if (invalid && !editMode) {
    return (
      <div style={{ padding: 10, color: 'red' }}>
        <Trans>
          There is no object connected in editor or connected object is not of
          "Opportunity" type!
        </Trans>
      </div>
    )
  }
  if (requestedAmountIds.length === 0) {
    return (
      <div style={{ color: 'red' }}>
        <b>
          <Trans>
            Either Milestones or Requested Amount field were not connected in
            form editor!
          </Trans>
        </b>
      </div>
    )
  }

  if (!projectStartDate && !projectEndDate) {
    disabled = true
    warning = (
      <div style={{ margin: 20 }}>
        <Typography style={{ marginLeft: 25, fontSize: '21px', color: 'red' }}>
          <Trans>
            You need to set project dates in Milestones to edit the project
            Budget!
          </Trans>
        </Typography>
      </div>
    )
  }

  const revenuesMap = revenuesLines.map((line) => ({
    key: line,
    value: myI18n?._(line)
  }))
  const expensesMap = expensesLines.map((line) => {
    return {
      key: sanitizeLineKey(line),
      value: myI18n?._(line)
    }
  })

  const createTable = () => {
    const columns = []
    const expensesHelpTextLabel = parseFormLabelText({
      text: expensesHelpText,
      langVersion,
      i18n
    })
    const revenuesHelpTextLabel = parseFormLabelText({
      text: revenuesHelpText,
      langVersion,
      i18n
    })
    let y = startDate
    const sums = {
      total: {
        revenues: 0,
        expenses: 0,
        revenuesTotal: Object.fromEntries(
          revenuesMap.map((item) => [item.key, 0])
        ),
        expenseTotal: Object.fromEntries(
          expensesMap.map((item) => [item.key, 0])
        )
      }
    }

    const baseLines = expenses || {}
    Object.keys(baseLines).forEach((key, index) => {
      columns.push(+key)
      sums[key] = {
        revenues: 0,
        expenses: 0
      }
    })
    for (y; y <= endDate; y++) {
      if (!columns.includes(y)) {
        columns.push(y)
        sums[y] = {
          revenues: 0,
          expenses: 0
        }
      }
    }

    // fill columns with 0 if new columns are added
    for (const i of columns) {
      if (!expenses[i]) {
        expenses[i] = Object.fromEntries(
          expensesMap.map((item) => [item.key, { value: 0 }])
        )
      }
      if (!revenues[i]) {
        revenues[i] = Object.fromEntries(
          revenuesMap.map((item) => [item.key, { value: 0 }])
        )

        const baseValue = Math.floor(
          +amountRequested / Object.keys(revenues).length
        )
        let remainder = amountRequested % Object.keys(revenues).length

        Object.keys(revenues).forEach((year, index) => {
          if (!revenues[year][budgetCentreGrantKey]) {
            revenues[year][budgetCentreGrantKey] = {}
          }
          revenues[year][budgetCentreGrantKey].value = baseValue
          if (remainder) {
            revenues[year][budgetCentreGrantKey].value++
            remainder--
          }
        })
      }
    }
    // count sums of fields

    columns.forEach((i, index) => {
      for (const j in revenues[i]) {
        sums[i].revenues += +revenues[i][j].value || 0
        sums.total.revenuesTotal[j] += +revenues[i][j].value || 0
      }
      for (const j in expenses[i]) {
        sums[i].expenses += +expenses[i][j].value || 0
        sums.total.expenseTotal[j] += +expenses[i][j].value || 0
      }
      sums.total.expenses += sums[i].expenses
      sums.total.revenues += sums[i].revenues
    })

    const columnsSorted = columns.sort((a, b) => a - b)
    const justOneColumn = columnsSorted.length === 1
    return (
      <Table
        style={{
          whiteSpace: 'pre',
          backgroundColor: '',
          border: 'solid 1px #cccccc',
          borderTop: 'none'
        }}
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: '#f5f5f5',
              borderTop: 'solid 1px #cccccc'
            }}
          >
            <TableCell
              style={{ textAlign: 'center' }}
              className={classes.labelColumn}
            >
              <Trans>Revenues</Trans>/<Trans>Expenses</Trans>
            </TableCell>
            {!justOneColumn && (
              <TableCell style={{ textAlign: 'center', maxWidth: 120 }}>
                <Trans>Total</Trans>
              </TableCell>
            )}
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    color: invalid && '#f5543b'
                  }}
                >
                  {' '}
                  {year}{' '}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              style={{ paddingLeft: 20 }}
              colSpan={
                justOneColumn
                  ? 1 + columnsSorted.length
                  : 2 + columnsSorted.length
              }
            >
              <b style={{ marginLeft: 10 }}>
                <Trans>Revenues</Trans>
              </b>
              <FormHelptext
                parseToHtml
                helpText={revenuesHelpTextLabel}
              />
            </TableCell>
          </TableRow>
          {revenuesMap.map((item, index) => {
            return (
              <>
                <TableRow key={index}>
                  <TableCell style={{ paddingLeft: 40 }}>
                    <Trans id={item.value} />
                  </TableCell>
                  {!justOneColumn && (
                    <TableCell
                      className={classes.dataColumn}
                      style={{
                        textAlign: 'center',
                        padding: 15
                      }}
                    >
                      <CurrencyFormated
                        value={sums.total.revenuesTotal[item.key]}
                      />
                    </TableCell>
                  )}
                  {columnsSorted.map((year, columnIndex) => {
                    const invalid = year > endDate || year < startDate
                    const nonEditable =
                      item.key === budgetCentreGrantKey &&
                      requestedAmountIds.length > 0

                    return (
                      <TableCell
                        key={columnIndex}
                        style={{ verticalAlign: 'top' }}
                      >
                        <Grid
                          container
                          direction='column'
                          style={{
                            height: '100%',
                            flexGrow: 1
                          }}
                        >
                          {nonEditable
                            ? (
                              <div style={{ padding: 6 }}>
                                <CurrencyFormated
                                  value={revenues[year][item.key]?.value}
                                />
                              </div>
                              )
                            : (
                              <MUTextField
                                id={
                                id + `.revenues[${year}]['${item.key}'].value`
                              }
                                useMultiuser={useMultiuser}
                                muBag={muBag}
                                displayFieldHistoryIcon
                                elementType='textInputNumeric'
                                style={{ paddingRight: 15 }}
                                InputProps={{
                                  inputComponent: CurrencyFormat
                                }}
                                inputProps={{
                                  style: {
                                    textAlign: 'center',
                                    color: invalid && '#f5543b'
                                  }
                                }}
                                size='small'
                                type='number'
                                placeholder={langVersion !== 'fr' ? '$0' : '0 $'}
                                disabled={
                                disabled ||
                                invalid ||
                                item.key === budgetCentreGrantKey
                              }
                              />
                              )}

                          <MUTextField
                            id={
                              id + `.revenues[${year}]['${item.key}'].comment`
                            }
                            label={myI18n._(t`Comments`)}
                            multiline
                            minRows={commentsRows || 1}
                            maxRows={
                              commentsExpandable ? null : commentsRows || 1
                            }
                            useMultiuser={useMultiuser}
                            muBag={muBag}
                            displayFieldHistoryIcon
                            style={{ paddingRight: 15, marginTop: 10 }}
                            inputProps={{
                              style: {
                                textAlign: 'center',
                                color: invalid && '#f5543b'
                              }
                            }}
                            size='small'
                            disabled={disabled || invalid}
                          />
                        </Grid>
                      </TableCell>
                    )
                  })}
                </TableRow>
                {item.key === budgetCentreGrantKey &&
                  meta.error &&
                  !meta.error.expenses && (
                    <div style={{ marginTop: 10 }}>
                      <FormLabel
                        msg={FormBudgetError(meta.error)}
                        error
                      />
                    </div>
                )}
              </>
            )
          })}
          <TableRow>
            <TableCell
              style={{ paddingLeft: 20 }}
              colSpan={
                justOneColumn
                  ? 1 + columnsSorted.length
                  : 2 + columnsSorted.length
              }
            >
              <b style={{ marginLeft: 10 }}>
                <Trans>Expenses</Trans>
              </b>
              <FormHelptext
                parseToHtml
                helpText={expensesHelpTextLabel}
              />
            </TableCell>
          </TableRow>
          {expensesMap.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ paddingLeft: 40 }}>
                <Trans id={item.value} />
              </TableCell>
              {!justOneColumn && (
                <TableCell className={classes.dataColumn}>
                  <CurrencyFormated value={sums.total.expenseTotal[item.key]} />
                </TableCell>
              )}
              {columnsSorted.map((year, columnIndex) => {
                const invalid = year > endDate || year < startDate
                return (
                  <TableCell key={columnIndex} style={{ verticalAlign: 'top' }}>
                    <MUTextField
                      id={id + `.expenses[${year}]['${item.key}'].value`}
                      useMultiuser={useMultiuser}
                      displayFieldHistoryIcon
                      elementType='textInputNumeric'
                      muBag={muBag}
                      InputProps={{
                        inputComponent: CurrencyFormat
                      }}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: invalid && '#f5543b'
                        }
                      }}
                      type='number'
                      size='small'
                      placeholder={langVersion !== 'en' ? '0 $' : '$0'}
                      style={{ paddingRight: 15 }}
                      disabled={disabled || invalid}
                    />

                    <MUTextField
                      id={id + `.expenses[${year}]['${item.key}'].comment`}
                      label={myI18n?._(t`Comments`)}
                      multiline
                      showErrorWithoutTouch
                      minRows={commentsRows || 1}
                      maxRows={commentsExpandable ? null : commentsRows || 1}
                      useMultiuser={useMultiuser}
                      displayFieldHistoryIcon
                      muBag={muBag}
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          color: invalid && '#f5543b'
                        }
                      }}
                      size='small'
                      style={{ paddingRight: 15, marginTop: 10 }}
                      disabled={disabled || invalid}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Sum of revenue</Trans>
              </b>
            </TableCell>
            {!justOneColumn && (
              <TableCell className={classes.dataColumn}>
                <CurrencyFormated value={sums.total.revenues} />
              </TableCell>
            )}
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated value={sums[year].revenues} />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Sum of expenses</Trans>
              </b>
            </TableCell>
            {!justOneColumn && (
              <TableCell className={classes.dataColumn}>
                <CurrencyFormated value={sums.total.expenses} />
              </TableCell>
            )}
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated value={sums[year].expenses} />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingLeft: 20 }}>
              <b>
                <Trans>Total of revenue - expenses</Trans>
              </b>
            </TableCell>
            {!justOneColumn && (
              <TableCell style={{ textAlign: 'center' }}>
                <CurrencyFormated
                  value={sums.total.revenues - sums.total.expenses}
                />
              </TableCell>
            )}
            {columnsSorted.map((year, index) => {
              const invalid = year > endDate || year < startDate
              return (
                <TableCell
                  key={index}
                  style={{
                    textAlign: 'center',
                    paddingRight: 30,
                    color: invalid && '#f5543b'
                  }}
                >
                  {sums[year] && (
                    <CurrencyFormated
                      value={sums[year].revenues - sums[year].expenses}
                    />
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        </TableFooter>
      </Table>
    )
  }

  return (
    <Paper className={classes.root}>
      <div style={{ padding: 20 }}>
        <h3>{title}</h3>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant='h6'>
              <Trans>Project Start Date:</Trans>
            </Typography>{' '}
            {projectStartDate}
            <br />
            <Typography variant='h6'>
              <Trans>Project End Date:</Trans>
            </Typography>{' '}
            {projectEndDate}
          </Grid>
        </Grid>
        <div style={{ padding: 20 }}>
          <DeleteExtraLinesButton
            setFieldValue={(id, value) => {
              if (useMultiuser) {
                endEditingField({
                  ...muBag,
                  fieldId: id,
                  fieldValue: value
                })
              }
              setFieldValue(id, value)
            }}
            disabled={disabled}
            id={id}
            value={value}
            start={projectStartDate}
            end={projectEndDate}
            reloadLastModifiedDates={reloadLastModifiedDates}
          />
        </div>
        {warning}
        {!meta?.error?.expenses && error}
        <div className='w-100 overflow-auto' style={{ padding: 20 }}>
          <TableContainer component={Paper}>{createTable()}</TableContainer>
        </div>
      </div>
    </Paper>
  )
}

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2)
    // width: 800
  },
  labelColumn: {
    width: 250
  },
  dataColumn: {
    textAlign: 'center'
  }
})

export const formBudgetParseValueToCompare = (v) => {
  const toRet = { ...v }
  return toRet
}

export const formBudgetValueToText = (v) => {
  const toRet = languages.reduce((acc, lang) => {
    acc[lang] = <Trans>Data is too complex to show differences!</Trans>
    return acc
  }, {})
  return toRet
}

export const revenuesMap = [
  {
    key: 'Transformation Centre Grant',
    value: t`Transformation Centre Grant`
  },
  { key: 'Other Grants', value: t`Other Grants` },
  { key: 'Fundraising/Donations', value: t`Fundraising/Donations` },
  { key: 'Internal Revenue', value: t`Internal Revenue` }
]

export const expensesMap = [
  {
    key: 'Personnel - Internal Wages and Benefits',
    value: t`Personnel - Internal Wages and Benefits`
  },
  {
    key: 'Personnel - External Professional/consultant',
    value: t`Personnel - External Professional/consultant`
  },
  {
    key: 'Direct costs (travel, materials, equipment, supplies, licensing, training costs)',
    value: t`Direct costs (travel, materials, equipment, supplies, licensing, training costs)`
  },
  {
    key: 'Indirect costs (Rental space, Insurance, Maintenance, etc)',
    value: t`Indirect costs (Rental space, Insurance, Maintenance, etc...)`
  },
  {
    key: 'Other',
    value: t`Other`
  }
]
export const budgetCentreGrantKey = 'Transformation Centre Grant'

export const sanitizeLineKey = (key) => {
  if (key === 'Indirect costs (Rental space, Insurance, Maintenance, etc...)') {
    return 'Indirect costs (Rental space, Insurance, Maintenance, etc)'
  } else {
    return key
  }
}

const parseLineKeySF = (key) => {
  if (key === 'Indirect costs (Rental space, Insurance, Maintenance, etc)') {
    return 'Indirect costs (Rental space, Insurance, Maintenance, etc...)'
  } else {
    return key
  }
}

Yup.addMethod(Yup.object, 'checkBudgetValidity', function () {
  return this.test('checkBudgetValidity', requiredTrans, function (object) {
    const sum = calcBudgetTotal(object)
    const { path, createError } = this
    if (sum.value !== 0) {
      return createError({
        path,
        message: (
          <Trans>Budget expenses are not equal to budget revenues</Trans>
        )
      })
    }
    return true
  })
})

Yup.addMethod(Yup.object, 'checkAmountRequestedValidity', function (ids) {
  return this.test(
    'checkAmountRequestedValidity',
    requiredTrans,
    function (object) {
      if (ids.length === 0) {
        return true
      }
      const { path, createError, parent } = this
      const requestedAmount = ids.reduce((acc, id) => {
        const value = Number(parent[id] || 0)
        return acc + value
      }, 0)
      let sum = 0
      for (const yearKey in object.revenues) {
        if (object.revenues[yearKey][budgetCentreGrantKey]) {
          const value = Number(
            object.revenues[yearKey][budgetCentreGrantKey].value
          )
          sum += value
        }
      }
      if (sum !== requestedAmount) {
        return createError({
          path,
          message: (
            <Trans>
              Amount requested and centre's grant in budget do not match!
            </Trans>
          )
        })
      }
      return true
    }
  )
})

Yup.addMethod(
  Yup.object,
  'checExpensesLinesValidity',
  function (validLines = []) {
    return this.test(
      'checExpensesLinesValidity',
      requiredTrans,
      function (object) {
        const { path, createError } = this
        const errors = []
        Object.keys(object).forEach((year) => {
          const budgetObj = object[year]
          Object.keys(budgetObj)
            .filter((line) => validLines.includes(line))
            .forEach((line) => {
              const valueObj = budgetObj[line]
              const expense = valueObj.value || 0
              if (expense && !valueObj.comment) {
                errors.push(
                  new Yup.ValidationError(
                    requiredTrans,
                    valueObj.comment,
                    path + `[${year}][${line}].comment`
                  )
                )
              }
            })
        })
        if (errors.length === 0) {
          return true
        }
        return new Yup.ValidationError(errors)
      }
    )
  }
)

export const formBudgetValidation = (item) => {
  const schemaObj = {}
  const {
    expensesMustMatchRevenues,
    expensesCommentsValidation,
    requestedAmountIds = []
  } = item.typeProps
  if (expensesCommentsValidation) {
    const validLines = item.typeProps.expensesLines || []
    schemaObj.expenses = Yup.object({}).checExpensesLinesValidity(
      validLines.map((line) => sanitizeLineKey(line))
    )
  }
  let toReturn =
    Yup.object(schemaObj).checkAmountRequestedValidity(requestedAmountIds)
  if (expensesMustMatchRevenues) {
    toReturn = toReturn.checkBudgetValidity()
  }
  return toReturn
}

export const FormBudgetExtractKey = ({ saveMap, value, connectedObjectId }) => {
  const inner = []
  const mapping = globalValues.budgetCategories.en_CA
  for (const year in value.revenues) {
    for (const category in value.revenues[year]) {
      inner.push({
        FGM_Portal__Amount__c: value.revenues[year][category].value || 0,
        FGM_Portal__Note__c: value.revenues[year][category].comment,
        Id: value.revenues[year][category].id,
        FGM_Portal__Category__c: mapping.revenues[category],
        FGM_Portal__Grantee_Budget__r: {
          Name: year
        }
      })
    }
    for (const category in value.expenses[year]) {
      inner.push({
        FGM_Portal__Amount__c: value.expenses[year][category].value || 0,
        FGM_Portal__Note__c: value.expenses[year][category].comment,
        Id: value.expenses[year][category].id,
        FGM_Portal__Category__c: mapping.expenses[parseLineKeySF(category)],
        FGM_Portal__Grantee_Budget__r: {
          Name: year
        }
      })
    }
  }
  saveMap[connectedObjectId].FGM_Portal__Grantee_Budget_Line_Items__r = inner
}

const calcBudgetTotal = (obj) => {
  let sum = currency(0)
  for (const yearKey in obj.revenues) {
    for (const section in obj.revenues[yearKey]) {
      sum = sum.add(currency(obj.revenues[yearKey][section].value ?? 0))
    }
  }
  for (const yearKey in obj.expenses) {
    for (const section in obj.expenses[yearKey]) {
      sum = sum.subtract(currency(obj.expenses[yearKey][section].value ?? 0))
    }
  }
  return sum
}

export const isBudgetLineValid = (line) => {
  if(line?.FGM_Portal__Grantee_Budget__r?.Name === 'All time'){
    return false
  }
  return true
}

export const formBudgetDefaultValue = (obj) => {
  const ret = {
    revenues: {},
    expenses: {},
    invalid: [],
    budgetTotal: 0
  }
  if (!obj || !obj.FGM_Portal__Grantee_Budget_Line_Items__r) {
    return ret
  }
  let year
  for (const line of obj.FGM_Portal__Grantee_Budget_Line_Items__r.records) {
    if (!line.FGM_Portal__Category__r) {
      continue
    }
    year = parseInt(line.FGM_Portal__Grantee_Budget__r.Name, 10)
    if(!isBudgetLineValid(line)){
      continue
    }

    if (
      line.FGM_Portal__Category__r.FGM_Portal__Parent_Category__r.Name ===
      'Revenue'
    ) {
      if (!ret.revenues[year]) {
        ret.revenues[year] = {}
      }
      const toSet = {
        value: line.FGM_Portal__Amount__c,
        id: line.Id,
        comment: line.FGM_Portal__Note__c || '',
        modifiedDate: line.LastModifiedDate
      }
      const alreadySet = ret.revenues[year][line.FGM_Portal__Category__r.Name]
      if (alreadySet) {
        if (
          moment(toSet.modifiedDate).isAfter(moment(alreadySet.modifiedDate))
        ) {
          ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
          ret.invalid.push(alreadySet)
        } else {
          ret.invalid.push(toSet)
        }
      } else {
        ret.revenues[year][line.FGM_Portal__Category__r.Name] = toSet
      }
    } else {
      if (!ret.expenses[year]) {
        ret.expenses[year] = {}
      }
      const toSet = {
        value: line.FGM_Portal__Amount__c,
        modifiedDate: line.LastModifiedDate,
        id: line.Id,
        comment: line.FGM_Portal__Note__c || ''
      }
      const lineKey = sanitizeLineKey(line.FGM_Portal__Category__r.Name)
      const alreadySet = ret.expenses[year][lineKey]
      if (alreadySet) {
        if (
          moment(toSet.modifiedDate).isAfter(moment(alreadySet.modifiedDate))
        ) {
          ret.expenses[year][lineKey] = toSet
          ret.invalid.push(alreadySet)
        } else {
          ret.invalid.push(toSet)
        }
      } else {
        ret.expenses[year][lineKey] = toSet
      }
    }
  }

  ret.budgetTotal = calcBudgetTotal(ret).value //convert back to number
  return ret
}

const useStyles = makeStyles(styles)

const DeleteExtraLinesButton = ({
  end,
  disabled,
  start,
  value,
  id,
  setFieldValue,
  reloadLastModifiedDates
}) => {
  const { expenses = {}, revenues = {} } = value
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [deleting, setDeleting] = useState(false)
  const endYear = moment(end).utc().year()
  const startYear = moment(start).utc().year()
  const invalidLines = []
  Object.keys(expenses).forEach((year) => {
    const yearObj = expenses[year]
    const array = Object.values(yearObj)
    const isInvalid = year > endYear || year < startYear
    array.forEach((line) => {
      if (isInvalid) {
        invalidLines.push(line)
      }
    })
  })
  Object.keys(revenues).forEach((year) => {
    const yearObj = revenues[year]
    const array = Object.values(yearObj)
    const isInvalid = year > endYear || year < startYear
    array.forEach((line) => {
      if (isInvalid) {
        invalidLines.push(line)
      }
    })
  })
  if (value.invalid) {
    value.invalid.forEach((line) => {
      invalidLines.push(line)
    })
  }

  return (
    (invalidLines.length > 0 || deleting) && (
      <div>
        <Typography style={{ marginBottom: 10, color: 'red' }}>
          <Trans>
            There are invalid (Either exceeding project date range you specified
            or created due to two users editing budget at the same time) budget
            lines added to your aplication!
          </Trans>
        </Typography>
        <Button
          disabled={deleting || disabled}
          variant='contained'
          color='primary'
          onClick={(e) => {
            setDeleting(true)
            const newValue = { ...value }
            newValue.invalid = []
            const toDelete = []
            const snackbar = enqueueSnackbar(
              <Trans>Deleting extra budget lines</Trans>,
              {
                variant: 'info',
                persist: true
              }
            )
            Object.keys(newValue.revenues).forEach((year) => {
              const isInvalid = year > endYear || year < startYear
              if (isInvalid) {
                delete newValue.revenues[year]
              }
            })
            Object.keys(newValue.expenses).forEach((year) => {
              const isInvalid = year > endYear || year < startYear
              if (isInvalid) {
                delete newValue.expenses[year]
              }
            })
            invalidLines.forEach((line) => {
              if (line.id) {
                toDelete.push(line.id)
              }
            })
            deleteBudgetLines(toDelete).then(
              (result) => {
                reloadLastModifiedDates().then((r) => {
                  setDeleting(false)
                  closeSnackbar(snackbar)
                  const isError = result.some((result) => !result.success)
                  if (isError) {
                    enqueueSnackbar(
                      <Trans>
                        Error ocurred while deleting extra budget lines
                      </Trans>,
                      {
                        variant: 'error'
                      }
                    )
                  } else {
                    enqueueSnackbar(<Trans>Deleted extra budget lines</Trans>, {
                      variant: 'success'
                    })
                    setFieldValue(id, newValue)
                  }
                })
              },
              (reject) => {
                setDeleting(false)
                closeSnackbar(snackbar)
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while deleting extra budget lines
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
            )
          }}
        >
          <Trans>Delete invalid budget lines</Trans>
        </Button>
      </div>
    )
  )
}

export const Budget = ({
  year,
  revenuesRender,
  expensesRender,
  editorPreview
}) => {
  return (
    <div key={year}>
      <div style={{ padding: 8, textAlign: 'center' }}>
        <Typography className='form-print-subtitle'>
          <Trans>Year</Trans>
        </Typography>
        <Typography style={{ fontSize: 21 }}>{year}</Typography>
      </div>

      {/* <div
        className='form-print-subtitle-big'
        style={{
          marginTop: 20,
          pageBreakBefore: 'always',
          breakBefore: 'always'
        }}
      >
        <Trans>Revenues</Trans>
      </div> */}
      <Paper elevation={6} style={{ marginTop: 10, padding: 10 }}>
        <Grid container>
          <Grid item style={{ padding: 4, width: 300 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Revenue</Trans>
            </Typography>
          </Grid>
          <Grid item style={{ padding: 4, width: 170 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Value</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Comment</Trans>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {revenuesRender.map((obj, index) => {
          return (
            <Grid container key={index}>
              <Grid item style={{ padding: 4, width: 300 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  <Trans id={obj.key} />
                </Typography>
              </Grid>
              <Grid item style={{ padding: 4, width: 170 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {obj.value !== '$ Value Placeholder'
                    ? (
                      <CurrencyFormated value={obj.value} />
                      )
                    : (
                      <Trans>$ Value Placeholder</Trans>
                      )}
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ minHeight: 21 }}>{obj.comment}</Typography>
              </Grid>
            </Grid>
          )
        })}
      </Paper>

      {/* <div
        className='form-print-subtitle-big'
        style={{
          marginTop: 20,
          pageBreakBefore: 'always',
          breakBefore: 'always'
        }}
      >
        <Trans>Expenses</Trans>
      </div> */}
      <Paper
        elevation={6}
        style={{ marginTop: 10, padding: 10 }}
        className='avoid-print-break'
      >
        <Grid container>
          <Grid item style={{ padding: 4, width: 300 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Expense</Trans>
            </Typography>
          </Grid>
          <Grid item style={{ padding: 4, width: 170 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Value</Trans>
            </Typography>
          </Grid>
          <Grid item xs style={{ padding: 4 }}>
            <Typography className='form-print-subtitle'>
              <Trans>Comment</Trans>
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {expensesRender.map((obj, index) => {
          return (
            <Grid container key={index}>
              <Grid item style={{ padding: 4, width: 300 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  <Trans id={obj.key} />
                </Typography>
              </Grid>
              <Grid item style={{ padding: 4, width: 170 }}>
                <Typography style={{ whiteSpace: 'pre-line' }}>
                  {obj.value !== '$ Value Placeholder'
                    ? (
                      <CurrencyFormated value={obj.value} />
                      )
                    : (
                      <Trans>$ Value Placeholder</Trans>
                      )}
                </Typography>
              </Grid>
              <Grid item xs style={{ padding: 4 }}>
                <Typography style={{ minHeight: 21 }}>{obj.comment}</Typography>
              </Grid>
            </Grid>
          )
        })}
      </Paper>
    </div>
  )
}
