import { MenuItem, TextField } from "@material-ui/core"
import { Trans } from '@lingui/macro'

/**
 * A component that renders a dropdown field (select box) for condition rules.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.value - The currently selected value.
 * @param {Function} props.onChange - Callback to handle changes in the selection.
 * @param {Object} props.rules - A collection of rules to populate the dropdown options.
 * @param {Object} [props.selectedElement] - The currently selected element that might influence rule text.
 * @returns {JSX.Element} A React component that renders a condition selection field.
 */
export default function ConditionField ({
  value,
  onChange,
  rules,
  selectedElement
}): JSX.Element {
  return (
    <TextField
      select
      variant='outlined'
      label={<Trans>Condition</Trans>}
      fullWidth
      value={value || ''}
      onChange={(e) => onChange(e)}
    >
      {Object.keys(rules)
        .filter((key) => !rules[key].deprecated)
        .map((key, index) => {
          const ruleObj = rules[key]
          let text = ruleObj.text

          if (selectedElement && typeof text === 'function') {
            text = text(selectedElement)
          }
          return (
            <MenuItem value={key} key={index}>
              {text}
            </MenuItem>
          )
        })}
    </TextField>
  )
}