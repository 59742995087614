import React from 'react'
import {
  Divider,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Trans } from '@lingui/macro'
import {
  formConditions,
  nonParameterConditions,
  sfFieldToConditionType
} from '../../editor/ConditionalElementEditor'
import ConditionField from '../conditions/ConditionField'
import ConditionTextParameterField from '../conditions/ConditionTextParameterFIeld'
import ConditionPicklistField from '../conditions/ConditionPicklistField'
import ConditionDatePickerField from '../conditions/ConditionDatePickerField'
import { Autocomplete } from '@material-ui/lab'

const supportedFieldTypes = [
  'string',
  'textarea',
  'picklist',
  'multipicklist',
  'currency',
  'percent',
  'double',
  'date',
  'datetime',
  'address',
  'email',
  'int',
  'phone',
  'boolean'
]

export interface FieldType {
  name: string
  label: string
  type: string
  picklistValues?: Array<{
    label?: string
    apiValue?: string
    value?: string
  }>
  sfField?: string
}

/**
 * Renders a form element that provides a UI for configuring table filters.
 * This includes creating, modifying, and deleting filters applied to collections of Salesforce (SF) objects.
 *
 * @category Form
 * @subcategory Form elements
 * @component
 * @returns {React.ReactNode} A JSX element displaying the table filters configuration interface.
 * @param {Object} props - Properties passed to the component.
 * @param {Object} props.typeProps - Configuration specific to the element that can be set in the form editor.
 * @param {Object[]} props.typeProps.tableFilters - Array of table filters that define conditions to apply to a collection of SF objects.
 * @param {number} props.depth - The depth level in the form hierarchy, used for dispatching changes to the Redux store.
 * @param {Object|null} props.selectedCollection - The currently selected collection containing Salesforce (SF) objects, including metadata like fields.
 * @param {Object[]} props.selectedCollection.fields - Metadata for fields in the collection.
 */

export default function ConfigureTableFilters ({
  typeProps,
  depth,
  selectedCollection
}): React.ReactNode {
  const dispatch = useDispatch()
  const {
    tableFilters = []
  } = typeProps

  const objectFields: FieldType[] = selectedCollection?.fields ?? []

  return (
    <>
      <Grid container direction='row' alignItems='center'>
        <Typography>
          <Trans>Table Filters</Trans>
        </Typography>
        <IconButton
          onClick={() => {
            const toSet = { ...typeProps }
            const newFilters = [...tableFilters]
            newFilters.push({})
            toSet.tableFilters = newFilters
            dispatch({
              type: 'FIELD',
              depth: depth.split('.'),
              fieldName: 'typeProps',
              fieldValue: toSet
            })
          }}
        >
          <Icon>add</Icon>
        </IconButton>
      </Grid>
      <Grid>
        {tableFilters.map((filter, index: number) => {
          const { childObject, condition } = filter
          const rulesType = filter?.type
          const rules = formConditions[rulesType]?.conditions
          const selectedField = objectFields?.find(field =>
            field.name === childObject
          )
          return (
            <React.Fragment key={index}>
              {index !== 0 && (
                <Divider className="my-10"  />
              )}
              <Grid
                item
                container
                direction='row'
              >
                <div className='mt-24'>{String(index + 1) + '. '}</div>
                <Grid item className='flex-1 p-10' >
                  <Autocomplete
                    fullWidth
                    value={typeof selectedField?.label === 'string' ? selectedField.label : ''}
                    onChange={(e, value) => {
                      let fieldValue
                      objectFields.some(item => {
                        const bool = item.label === value
                        if (bool) {
                          fieldValue = item.name
                        }
                        return bool
                      })
                      if(!fieldValue) return

                      const toSet = { ...typeProps }
                      const newFilters = [...tableFilters]
                      newFilters[index].childObject = fieldValue

                      const fieldTarget = selectedCollection?.fields?.find(field =>
                        field.name === fieldValue
                      )
                      const rulesType = sfFieldToConditionType[fieldTarget?.type]
                      newFilters[index].type = rulesType
                      toSet.tableFilters = newFilters
                      delete newFilters[index].condition
                      delete newFilters[index].parameter
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                    options={objectFields
                      .filter(field => supportedFieldTypes.includes(field.type))
                      .map((field) => {return field.label})
                    }
                    renderInput={params => (
                      <TextField
                        variant='outlined'
                        {...params}
                        label={<Trans>Filter target</Trans>}
                      />
                    )}
                  />
                  
                  <Grid container direction='row' className='mt-10'>
                    {Boolean(rules) &&
                      <>
                        <Grid
                          xs={
                            ['bool', 'otherGrants'].includes(rulesType) ||
                            ['isEmpty', 'isNotEmpty'].includes(condition)
                              ? 12
                              : 6
                          }
                          item
                        >
                          <ConditionField
                            value={condition}
                            onChange={(e) => {
                              const toSet = { ...typeProps }
                              const newFilters = [...tableFilters]
                              newFilters[index].condition = e.target.value
                              toSet.tableFilters = newFilters
                              if (nonParameterConditions.includes(e.target.value)) {
                                delete newFilters[index].parameter
                              }
                              dispatch({
                                type: 'FIELD',
                                depth: depth.split('.'),
                                fieldName: 'typeProps',
                                fieldValue: toSet
                              })
                            }}
                            rules={rules}
                            selectedElement={selectedField}
                          />
                        </Grid>
                        {['textInputNumeric',
                          'textInput',
                          'numericSlider',
                          'textInputArray',
                          'route',
                          'budget'
                        ].includes(rulesType) && (
                          <Grid xs={6} item>
                            <ConditionTextParameterField
                              value={filter.parameter}
                              rulesType={rulesType}
                              onChange={(e) => {
                                const toSet = { ...typeProps }
                                const newFilters = [...tableFilters]
                                newFilters[index].parameter = e.target.value
                                toSet.tableFilters = newFilters
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: toSet
                                })
                              }}
                            />
                          </Grid>
                        )}
                        {rulesType === 'picklist' &&
                          !['isEmpty', 'isNotEmpty'].includes(condition) &&
                            <Grid xs={6} item>
                              <ConditionPicklistField
                                selectedElement={selectedField}
                                item={filter}
                                onChange={(e) => {
                                  const toSet = { ...typeProps }
                                  const newFilters = [...tableFilters]
                                  newFilters[index].parameter = e.target.value
                                  toSet.tableFilters = newFilters
                                  dispatch({
                                    type: 'FIELD',
                                    depth: depth.split('.'),
                                    fieldName: 'typeProps',
                                    fieldValue: toSet
                                  })
                                }}
                              />
                            </Grid>}
                        {rulesType === 'datePicker' &&
                          <Grid xs={6} item>
                            <ConditionDatePickerField
                              item={filter}
                              onChange={(e) => {
                                const toSet = { ...typeProps }
                                const newFilters = [...tableFilters]
                                newFilters[index].parameter = e
                                toSet.tableFilters = newFilters
                                dispatch({
                                  type: 'FIELD',
                                  depth: depth.split('.'),
                                  fieldName: 'typeProps',
                                  fieldValue: toSet
                                })
                              }}
                            />
                          </Grid>}
                      </>}
                  </Grid>
                </Grid>
                <div className='icon-button-container'>
                  <IconButton
                    onClick={() => {
                      const toSet = { ...typeProps }
                      const newFilters = [...tableFilters]
                      newFilters.splice(index, 1)
                      toSet.tableFilters = newFilters
                      dispatch({
                        type: 'FIELD',
                        depth: depth.split('.'),
                        fieldName: 'typeProps',
                        fieldValue: toSet
                      })
                    }}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </div>
              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}
