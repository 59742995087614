import { Trans } from '@lingui/macro'
import SFAuthService from 'app/services/sfAuth/SFAuthService'
import { checkContactByFlow } from 'app/services/sfAuth/sfData/sfContact'
import {
  getDocumentsByEntity,
  parseDocument
} from 'app/services/sfAuth/sfData/sfFiles'
import { describe } from 'app/services/sfAuth/sfData/sfSurvey'
import { getUserByFlow } from 'app/services/sfAuth/sfData/sfUser'
import { languages } from 'translation/I18nConnectedProvider'
import { getMainConnected, mapFormElements } from './Form'
import { formElementAdditionalQueries } from './common/formQueries'
import { formComponentTypes } from './components/formComponentTypes'
import { formObjectsToConnect } from './editor/FormWizard'

const baseSFObjectFields = ['Id', 'LastModifiedDate']

const checkStandartFieldsForReferences = ({
  item,
  checkStringForReferences
}) => {
  const textKeys = ['title', 'helpText', 'tooltip']
  textKeys.forEach(key => {
    languages.forEach(lang => {
      if (item?.[key]?.[lang]) {
        checkStringForReferences(item[key][lang])
      }
    })
  })

  if (item?.typeProps?.html) {
    languages.forEach(lang => {
      if (item.typeProps.html[lang]) {
        checkStringForReferences(item.typeProps.html[lang])
      }
    })
  }

  if (item.typeProps?.imgLink) {
    checkStringForReferences(item.typeProps.imgLink)
  }
}

export const connectedObjectQuery = (data, props = {}) => {
  const {
    returnOnlyDescribe = false,
    id,
    langVersion = 'en',
    handleObjectMissing,
    enqueueSnackbar
  } = props
  const conn = SFAuthService.getConnection()
  const toInclude = []
  const map = mapFormElements(data, langVersion)
  const idsMap = {}
  if (!id) {
    return Promise.resolve().then(r => ({ connectedMap: {}, describeMap: {} }))
  }
  const addressArray = id.split(';')
  addressArray.forEach(sub => {
    const arr = sub.split('=')
    if (arr[1] !== 'undefined') {
      idsMap[arr[0]] = arr[1]
    }
  })
  const promises = []
  const toDescribe = []
  const usedObjects = []
  const promisesMap = {}
  const identIdToType = {}
  const idToType = {}
  const identIdToName = {}
  const fieldsToSelect = {}
  const idToName = {}
  const nameToType = {}

  data.objectsConnected.forEach(object => {
    identIdToName[object.identId] = object.name
    const objId = idsMap[object.identId]
    if (object.type) {
      if (!usedObjects.includes(object.type)) {
        usedObjects.push(object.type)
      }
      idToType[objId] = object.type
      identIdToType[object.identId] = object.type
      nameToType[object.name] = object.type
    }
    if (objId === 'NO_OBJECT_ID') {
      return
    } else if (objId) {
      if (promisesMap[object.type]) {
        promisesMap[object.type].push(objId)
      } else {
        promisesMap[object.type] = [objId]
      }
    }
    idToName[objId] = object.name
  })
  const { referencedFields, referencedCollections } = getFieldsReferences({
    data,
    identIdToName
  })

  usedObjects.forEach(objName => {
    const sfObjectData = formObjectsToConnect[objName]
    if (!toDescribe.includes(objName)) {
      toDescribe.push(objName)
    }
    if (sfObjectData.additionalObjects) {
      sfObjectData.additionalObjects.forEach(obj => {
        const { sfObject, additionalObjects, collection, key } = obj
        let valid = false
        if (collection) {
          Object.keys(referencedCollections).some(name => {
            const type = nameToType[name]
            const bool = type === objName
            const referenced = referencedCollections[name] || []
            if (bool && referenced.includes(key)) {
              valid = true
            }
            return bool
          })
        } else {
          valid = true
        }
        if (valid) {
          if (!obj.disabled) {
            if (!toDescribe.includes(sfObject)) {
              toDescribe.push(sfObject)
            }
            if (additionalObjects) {
              additionalObjects.forEach(obj => {
                if (!obj.disabled && !toDescribe.includes(obj.sfObject)) {
                  toDescribe.push(obj.sfObject)
                }
              })
            }
          }
        }
      })
    }
    if (sfObjectData.describeAdditional) {
      sfObjectData.describeAdditional.forEach(sfObject => {
        if (!toDescribe.includes(sfObject)) {
          toDescribe.push(sfObject)
        }
      })
    }
  })

  // TODO improve - fetch only files that are referenced in upload files element to avoid getting broken query with too many files
  return Promise.all(toDescribe.map(name => describe(name))).then(
    describesResult => {
      const describeMap = {}
      describesResult.forEach(obj => {
        describeMap[obj.name] = obj
      })

      if (returnOnlyDescribe) {
        Object.values(describeMap).forEach(obj => {
          const fieldsMap = {}
          obj.fields.forEach(field => (fieldsMap[field.name] = field))
          obj.fieldsMap = fieldsMap
        })
        return { describeMap }
      }

      Object.keys(promisesMap).forEach(objName => {
        const toFetch = promisesMap[objName]
        const sfObjectData = formObjectsToConnect[objName]
        if (!sfObjectData || !toFetch) {
          return
        }
        let objPromise = conn.sobject(objName).find({
          Id: { $in: toFetch }
        })
        // Get referenced fields to select in query from text references and base select fields
        data.objectsConnected.forEach(object => {
          const validFields = describeMap[object.type].fields.map(
            field => field.name
          )
          const textReferenceFields = referencedFields[object.name] || []
          // Get base select fields for an object
          if (!fieldsToSelect[object.type]) {
            fieldsToSelect[object.type] = formObjectsToConnect[object.type]
              .select
              ? [...formObjectsToConnect[object.type].select]
              : []
          }
          const fieldsToLoop = [...textReferenceFields]
          baseSFObjectFields.forEach(field => {
            if (!fieldsToLoop.includes(field)) {
              fieldsToLoop.push(field)
            }
          })
          // Check if field exists on SF object
          fieldsToLoop
            .filter(field => field)
            .forEach(field => {
              if (
                !fieldsToSelect[object.type].includes(field) &&
                validFields.includes(field)
              ) {
                fieldsToSelect[object.type].push(field)
              } else if (!validFields.includes(field) && !field.includes('.')) {
                console.error(
                  'There is invalid reference in form: ',
                  object.type,
                  field
                )
              }
            })
        })

        const additionalQueriesAdded = []

        // Get referenced fields to select in query from fields connected to form elments
        Object.keys(map).forEach(key => {
          const item = map[key]
          const { connectedTo, options } = item.typeProps

          if (connectedTo) {
            connectedTo.forEach(connObj => {
              const { connectsToMultipleObjects, extraFields } =
                formComponentTypes[item.elementType]
              const fieldName = connObj.connectedField?.name
              const objType = identIdToType[connObj.connectedObject]

              let validFields = []
              const addFieldToSelect = field => {
                if (!fieldsToSelect[objType]) {
                  enqueueSnackbar(
                    <Trans>FORM_LOADING_ERROR_INVALID_OBJECT_CONNECTION</Trans>,
                    { variant: 'error' }
                  )
                }
                const toIterate = Array.isArray(field) ? field : [field]
                toIterate.forEach(fieldName => {
                  if (
                    !fieldsToSelect[objType].includes(fieldName) &&
                    validFields.includes(fieldName)
                  ) {
                    fieldsToSelect[objType].push(fieldName)
                  } else if (
                    !validFields.includes(fieldName) &&
                    !fieldName.includes('.')
                  ) {
                    console.error(
                      'There is invalid reference in form: ',
                      objType,
                      fieldName
                    )
                  }
                })
              }

              if (objType) {
                validFields = describeMap[objType].fields.map(
                  field => field.name
                )
                if (fieldName) {
                  addFieldToSelect(fieldName, objType)
                }
              }

              if (options) {
                options.forEach(option => {
                  const { connectedField } = option
                  if (connectedField) {
                    addFieldToSelect(connectedField.name)
                  }
                })
              }

              // Add fields that are needed for specific form element
              if (extraFields && objType) {
                extraFields.forEach(field => {
                  if (!fieldsToSelect[objType].includes(field)) {
                    fieldsToSelect[objType].push(field)
                  }
                })
              }

              if (connectsToMultipleObjects) {
                connectsToMultipleObjects.forEach(({ type, select }) => {
                  // Check if the type of object we iterate through matches the object requested by form element
                  if (objName === type && select) {
                    addFieldToSelect(select)
                  }
                })
              }

              // TODO: optimize to go for all keys instead of hard coded ones
              if (item.elementType === 'googleMapsPicker') {
                const extraConnects = [
                  'city',
                  'street',
                  'zipCode',
                  'latitude',
                  'longitude'
                ]
                extraConnects.forEach(key => {
                  if (connObj[key] && connObj[key].name) {
                    const fieldName = connObj[key].name
                    addFieldToSelect(fieldName, objType)
                  }
                })
              } else if (item.elementType === 'cenzusDivision') {
                const extraConnects = [
                  'cenzusDivision',
                  'pilotPhaseScopeType',
                  'pilotPhase',
                  'regionalScope',
                  'cities',
                  'provinces'
                ]
                extraConnects.forEach(key => {
                  if (connObj[key] && connObj[key].name) {
                    const fieldName = connObj[key].name
                    addFieldToSelect(fieldName, objType)
                  }
                })
              }
            })
          }
        })

        if (fieldsToSelect[objName]) {
          objPromise = objPromise.select(fieldsToSelect[objName].join(','))
        }
        if (sfObjectData.include) {
          sfObjectData.include.forEach(include => {
            objPromise = objPromise.include(include).end()
            toInclude.push(include)
          })
        }
        if (sfObjectData.additionalObjects) {
          sfObjectData.additionalObjects
            .filter(obj => {
              let refCollectionsArray = []
              Object.keys(referencedCollections).some(key => {
                if (nameToType[key] === objName) {
                  refCollectionsArray = referencedCollections[key]
                }
                return nameToType[key] === objName
              })
              return (
                obj.collection &&
                refCollectionsArray.includes(obj.key) &&
                obj.include
              )
            })
            .forEach(obj => {
              objPromise = objPromise.include(obj.include).end()
              toInclude.push(obj.include)
            })
        }

        const emptyPromise = () => Promise.resolve({})
        const addPromise = sfObjectData.additionalInfo || emptyPromise
        Object.keys(map).forEach(key => {
          const item = map[key]
          const elementData = formComponentTypes[item.elementType]
          const { connectedObject } = getMainConnected(item)
          const handleAddInclude = (
            include,
            includeSelect,
            additionalPromise
          ) => {
            // Check if we didn't include the subquery already
            if (!toInclude.includes(include)) {
              toInclude.push(include)
              objPromise = objPromise.include(include)
              if (includeSelect) {
                objPromise = objPromise.select(includeSelect)
              }
              objPromise = objPromise.end()
            }
          }

          if (elementData) {
            const { connectsToMultipleObjects, include, includeSelect } =
              elementData
            // If form element connects to multiple SF object, we iterate through them to add necessary include() and select() calls for each one
            if (connectsToMultipleObjects) {
              connectsToMultipleObjects.forEach(
                ({ type, select, include, includeSelect }) => {
                  // Check if the type of object we iterate through matches the object requested by form element
                  if (objName === type) {
                    if (Array.isArray(include)) {
                      include.forEach(obj => {
                        const { base, select } = obj
                        handleAddInclude(base, select)
                      })
                    } else if (include) {
                      handleAddInclude(include, includeSelect)
                    }
                  }
                }
              )
            } else if (include && connectedObject) {
              // Add to query select() and include() calls based on form element requiring them
              if (objName === identIdToType[connectedObject]) {
                handleAddInclude(include, includeSelect)
              }
            }
          }
        })

        // Handle any additional queries coming from form elements. This cannot be done earlier due to breaking include JSForce  queries
        Object.keys(map).forEach(key => {
          const item = map[key]
          const elementData = formComponentTypes[item.elementType]

          if (elementData) {
            const { connectsToMultipleObjects } = elementData
            if (connectsToMultipleObjects) {
              connectsToMultipleObjects.forEach(({ type, additionalKey }) => {
                // Check if the type of object we iterate through matches the object requested by form element
                if (objName === type && additionalKey && !additionalQueriesAdded.includes(additionalKey)) {
                  additionalQueriesAdded.push(additionalKey)
                  objPromise = objPromise.then(result => {
                    return formElementAdditionalQueries[additionalKey](result, { item, idsMap })
                  })
                }
              })
            }
          }
        })

        promises.push(
          Promise.all([
            objPromise,
            Promise.all([
              ...toFetch.map(id => {
                return addPromise(id, referencedCollections[idToName[id]])
              })
            ]),
            Promise.all([
              ...toFetch.map(id =>
                idToType[id] === 'User'
                  ? Promise.resolve([])
                  : getDocumentsByEntity(id)
              )
            ])
          ])
        )
      })
      return Promise.all(promises).then(resultArray => {
        const idsToIdentMap = {}
        const addressArray = id.split(';')
        addressArray
          .filter(
            sub =>
              // This filters unecessary ids that were provided in the url so they won't throw access error
              idToName[sub.split('=')[1]]
          )
          .forEach(sub => {
            const arr = sub.split('=')
            if (idsToIdentMap[arr[1]]) {
              enqueueSnackbar(
                'The same id was provided for different objects. This is not supported any may cause issues',
                { variant: 'error' }
              )
            } else {
              idsToIdentMap[arr[1]] = arr[0]
            }
          })
        const connectedMap = {}
        resultArray.forEach(arr => {
          const objects = arr[0]
          const additionalInfos = arr[1]
          const files = arr[2]
          objects.forEach((obj, index) => {
            const fieldsMap = {}
            const objectType = obj.attributes.type
            let additionalInfo = additionalInfos[index] || {}
            if (formObjectsToConnect[objectType]?.additionalInfoAfterFetch) {
              additionalInfo = {
                ...additionalInfo,
                ...formObjectsToConnect[objectType].additionalInfoAfterFetch(
                  obj
                )
              }
            }
            describeMap[objectType].fields.forEach(
              field => (fieldsMap[field.name] = field)
            )
            connectedMap[idsToIdentMap[obj.Id]] = {
              objectType: obj.attributes.type,
              identName: identIdToName[idsToIdentMap[obj.Id]],
              sfObject: obj,
              files: files[index].map((item, index) => ({
                ...parseDocument(item),
                uploadId: index
              })),
              additionalInfo,
              fieldsMap
            }
          })
        })

        let objectMissing
        Object.keys(idsToIdentMap)
          .filter(sfId => sfId)
          .forEach(sfId => {
            const identId = idsToIdentMap[sfId]
            if (!connectedMap[identId]) {
              objectMissing = true
              console.warn('No access to object: ', sfId)
            }
          })
        if (objectMissing && handleObjectMissing) {
          handleObjectMissing()
        }
        const referencesToFetch = getReferencedObjects({ data, connectedMap })
        // Fetch all all objects referenced in form texts etc.
        if (referencesToFetch.length > 0) {
          const conn = SFAuthService.getConnection()
          const toFetch = {}
          referencesToFetch.forEach(obj => {
            if (!toFetch[obj.id]) {
              toFetch[obj.id] = obj
            }
          })
          return Promise.allSettled(
            Object.values(toFetch).map(obj => {
              let promise
              if (obj.type === 'Contact') {
                promise = checkContactByFlow({
                  fetchId: obj.id
                }).then(result => result[0].outputValues?.contactsFound[0])
              } else if (obj.type === 'User') {
                promise = getUserByFlow({ id: obj.id })
              } else {
                promise = conn
                  .sobject(obj.type)
                  .find({
                    Id: obj.id
                  })
                  .then(r => r[0])
              }
              return promise
            })
          ).then(
            result => {
              const objects = result
                .filter(obj => obj.status === 'fulfilled')
                .map(obj => obj.value)
                .filter(obj => obj)
              const isError = result.some(obj => obj.status === 'rejected')
              if (isError) {
                if (enqueueSnackbar) {
                  enqueueSnackbar(
                    <Trans>
                      Error ocurred while loading referenced objects!
                    </Trans>,
                    {
                      variant: 'error'
                    }
                  )
                }
              }
              console.log('found reference objects', result, toFetch)
              const objectsFound = {}
              objects.forEach(obj => {
                objectsFound[obj.Id] = obj
              })
              referencesToFetch.forEach(obj => {
                if (objectsFound[obj.id]) {
                  connectedMap[obj.parent].sfObject[obj.reference] =
                    objectsFound[obj.id]
                }
              })
              return { connectedMap, describeMap }
            },
            reject => {
              console.log('error fetchin referenced objects', reject, toFetch)
              if (enqueueSnackbar) {
                enqueueSnackbar(
                  <Trans>
                    Error ocurred while loading referenced objects!
                  </Trans>,
                  {
                    variant: 'error'
                  }
                )
              }
              return { connectedMap, describeMap }
            }
          )
        } else {
          return { connectedMap, describeMap }
        }
      })
    }
  )
}

export const getReferencedObjects = ({ data, connectedMap }) => {
  const referencedIds = []
  const referencedObjects = []
  const referencedCollections = []
  const connectedMapByName = {}
  Object.keys(connectedMap).forEach(key => {
    const obj = connectedMap[key]
    connectedMapByName[obj.identName] = { ...obj, key }
  })

  const checkStringForReferences = string => {
    if (string && typeof string === 'string') {
      const occurrences = string.match(/!{([^}]*)}/g)
      if (occurrences) {
        occurrences.forEach(occurrence => {
          if (occurrence.includes('__r')) {
            let object = occurrence.substring(
              occurrence.indexOf('{') + 1,
              occurrence.indexOf('.')
            )
            if (object.includes('T/')) {
              object = object.replaceAll('T/', '')
            }
            const field = occurrence.substring(
              occurrence.indexOf('.') + 1,
              occurrence.indexOf('__r')
            )
            const sfObjectData = connectedMapByName[object]
            if (sfObjectData) {
              const { fieldsMap, sfObject } = sfObjectData
              const fieldData =
                fieldsMap[field + '__c'] ||
                fieldsMap[field] ||
                fieldsMap[field + 'Id']
              if (fieldData && fieldData.type === 'reference') {
                const objectReference = fieldData.referenceTo[0]
                let fieldValue =
                  sfObject[field + '__c'] ||
                  sfObject[field] ||
                  sfObject[field + 'Id']
                if (fieldValue) {
                  if (fieldValue.Id) {
                    fieldValue = fieldValue.Id
                  }
                  // Don't duplicate objects to fetch
                  if (!referencedIds.includes(fieldValue)) {
                    referencedObjects.push({
                      reference: field + '__r',
                      id: fieldValue,
                      type: objectReference,
                      parent: sfObjectData.key
                    })
                    referencedIds.push(fieldValue)
                  }
                }
              } else {
                // TODO handle collection references?
              }
            }
          }
        })
      }
    }
  }

  const checkElementForReferences = item => {
    checkStandartFieldsForReferences({ item, checkStringForReferences })

    if (item.elementType === 'textWithReferences') {
      const { content = [] } = item.typeProps
      content.forEach(item => {
        if (item.type === 'text') {
          languages.forEach(lang => {
            if (item?.text?.[lang]) {
              checkStringForReferences(item.text[lang])
            }
          })
        }
      })
    }
  }

  // Check form title for references
  languages.forEach(lang => {
    const text = data?.title?.[lang].text
    if (text) {
      checkStringForReferences(text)
    }
  })

  if (data.pdfProps) {
    languages.forEach(lang => {
      const { header, footer } = data.pdfProps
      if (header?.text?.[lang]) {
        checkStringForReferences(header.text?.[lang])
      }
      if (footer?.text?.[lang]) {
        checkStringForReferences(footer.text?.[lang])
      }
    })
  }

  data.sections.forEach(section => {
    languages.forEach(lang => {
      if (section?.title?.[lang]) {
        checkStringForReferences(section.title?.[lang])
      }
    })
    section.elements.forEach(element => {
      const loopElement = element => {
        if (element.elements) {
          checkElementForReferences(element)
          element.elements.forEach(child => {
            loopElement(child)
          })
        } else {
          checkElementForReferences(element)
        }
      }
      loopElement(element)
    })
  })
  console.log(
    'Found additional reference objects to fetch: ',
    referencedObjects
  )
  return referencedObjects
}

export const getFieldsReferences = ({ data, identIdToName }) => {
  const referencedFields = {}
  const referencedCollections = {}

  const addReference = (objectName, field, collection = false) => {
    if (field && objectName) {
      const toAdd = collection ? referencedCollections : referencedFields
      if (toAdd[objectName] && !toAdd[objectName].includes(field)) {
        toAdd[objectName].push(field)
      } else if (!toAdd[objectName]) {
        toAdd[objectName] = [field]
      }
    }
  }

  const checkStringForReferences = string => {
    if (string && typeof string === 'string') {
      const occurrences = string.match(/!{([^}]*)}/g)
      if (occurrences) {
        occurrences.forEach(occurrence => {
          let object = occurrence.substring(
            occurrence.indexOf('{') + 1,
            occurrence.indexOf('.')
          )
          if (object.includes('T/')) {
            object = object.replaceAll('T/', '')
          }
          if (object === 'SPECIAL') {
            return
          }
          let field = occurrence.substring(occurrence.indexOf('.') + 1)
          let valid = false
          if (field.slice(-1) === '}' && !occurrence.includes('__r')) {
            field = field.slice(0, field.length - 1)
            valid = true
          } else if (occurrence.includes('__r')) {
            field = occurrence.substring(
              occurrence.indexOf('.') + 1,
              occurrence.indexOf('__r')
            )
            field = field + '__c'
            valid = true
          }
          if (valid) {
            addReference(object, field)
          }
        })
      }
    }
  }

  const checkConditionForReferences = condition => {
    const { sfField, sfObject } = condition
    if (sfField && sfObject) {
      const objectName = identIdToName[sfObject]
      addReference(objectName, sfField)
    }
  }

  const checkElementForReferences = item => {
    if (item.typeProps.connectedTo) {
      item.typeProps.connectedTo.forEach((obj, index) => {
        const objectName = identIdToName[obj.connectedObject]
        if (obj.connectedObject) {
          const itemData = formComponentTypes[item.elementType]
          if (itemData && itemData.relatedCollections) {
            itemData.relatedCollections.forEach(collectionKey => {
              addReference(objectName, collectionKey, true)
            })
          }
        }
        if (obj.connectedCollection) {
          addReference(objectName, obj.connectedCollection, true)
        }
      })
    }

    if (item.conditions) {
      item.conditions.forEach(condition => {
        if (condition.conditions) {
          condition.conditions.forEach(subcondition => {
            checkConditionForReferences(subcondition)
          })
        } else {
          checkConditionForReferences(condition)
        }
      })
    }

    checkStandartFieldsForReferences({ item, checkStringForReferences })

    if (item.elementType === 'textWithReferences') {
      const { content = [] } = item.typeProps
      content.forEach(item => {
        const {
          type,
          referenceField,
          referenceObject,
          text,
          referenceCollection
        } = item
        if (type === 'text') {
          languages.forEach(lang => {
            if (text?.[lang]) {
              checkStringForReferences(text?.[lang])
            }
          })
        } else if (
          type === 'fieldReference' &&
          referenceField &&
          referenceObject
        ) {
          const objectName = identIdToName[referenceObject]
          addReference(objectName, referenceField)
        } else if (
          type === 'collectionReference' ||
          type === 'wholeCollectionReference'
        ) {
          const objectName = identIdToName[referenceObject]
          addReference(objectName, referenceCollection, true)
        }
      })
    }
  }

  // Check form title for references
  languages.forEach(lang => {
    const text = data?.title?.[lang].text
    if (text) {
      checkStringForReferences(text)
    }
  })

  if (data.pdfProps) {
    languages.forEach(lang => {
      const { header, footer } = data.pdfProps
      if (header?.text?.[lang]) {
        checkStringForReferences(header?.text?.[lang])
      }
      if (footer?.text?.[lang]) {
        checkStringForReferences(footer.text?.[lang])
      }
    })
  }

  data.sections.forEach(section => {
    if (section.conditions) {
      section.conditions.forEach(condition => {
        if (condition.conditions) {
          condition.conditions.forEach(subcondition => {
            checkConditionForReferences(subcondition)
          })
        } else {
          checkConditionForReferences(condition)
        }
      })
    }
    languages.forEach(lang => {
      if (section?.title?.[lang]) {
        checkStringForReferences(section.title?.[lang])
      }
    })
    section.elements.forEach(element => {
      const loopElement = element => {
        if (element.elements) {
          languages.forEach(lang => {
            if (element?.title?.[lang]) {
              checkStringForReferences(element.title[lang])
            }
          })
          element.elements.forEach(child => {
            loopElement(child)
          })
        } else {
          checkElementForReferences(element)
        }
      }
      loopElement(element)
      if (element.conditions) {
        element.conditions.forEach(condition => {
          if (condition.conditions) {
            condition.conditions.forEach(subcondition => {
              checkConditionForReferences(subcondition)
            })
          } else {
            checkConditionForReferences(condition)
          }
        })
      }
    })
  })
  console.log(
    'Extracted referenced fields and collections to fetch: ',
    referencedFields,
    referencedCollections
  )
  return { referencedFields, referencedCollections }
}
