import { t } from '@lingui/macro'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { inuktitutFormPdfFontFamily } from 'app/views/forms/pdf-components/FormPdf'
import { parsePDFLabelToSupportWordBreaking } from 'app/views/forms/pdf-components/formPdfUtils'
import { pdfTableStyles } from 'app/views/forms/pdf-components/PDFCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

export const FormProjectAffiliationsFillablePDF = ({
  typeProps = {},
  title,
  value = [],
  langVersion,
  ...props
}) => {
  const { roles } = typeProps
  const currentLanguage = myI18n.language
  const referenceRoles = roles.some((obj) => obj.askForRelationship)
  const columns = referenceRoles ? 6 : 5
  const styles = {
    ...pdfTableStyles,
    ...StyleSheet.create({
      equalColumn: {
        width: 75 / columns + '%'
      }
    })
  }

  if (currentLanguage === 'iu-cans') {
    styles.tableCell.fontFamily = inuktitutFormPdfFontFamily
    styles.mainTableCell.fontFamily = inuktitutFormPdfFontFamily
  }

  const roleToTrans = {}
  roles.forEach((role) => {
    if (role?.apiValue && role?.label) {
      roleToTrans[role.apiValue] = role?.label?.portalTranslationId
        ? myI18n._(role.label.portalTranslationId)
        : role?.label?.[currentLanguage]
    }
  })

  return (
    <View>
      <View style={styles.table}>
        <View style={styles.tableRow} wrap={false}>
          <View style={[styles.tableCol, { width: '25%' }]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Contact role`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.equalColumn]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`First Name`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.equalColumn]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Last Name`)}
            </Text>
          </View>

          {!referenceRoles
            ? (
              <View style={[styles.tableCol, styles.equalColumn]}>
                <Text style={styles.mainTableCell}>
                  {myI18n._(t`Job title`)}
                </Text>
              </View>
              )
            : (
              <>
                <View style={[styles.tableCol, styles.equalColumn]}>
                  <Text style={styles.mainTableCell}>
                    {parsePDFLabelToSupportWordBreaking(
                      myI18n._(t`Relationship to provider`)
                    )}
                  </Text>
                </View>
                <View style={[styles.tableCol, styles.equalColumn]}>
                  <Text style={styles.mainTableCell}>
                    {myI18n._(t`Organization`)}
                  </Text>
                </View>
              </>
              )}

          <View style={[styles.tableCol, styles.equalColumn]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`E-mail`)}
            </Text>
          </View>
          <View style={[styles.tableCol, styles.equalColumn]}>
            <Text style={styles.mainTableCell}>
              {myI18n._(t`Phone`)}
            </Text>
          </View>
        </View>

        {roles.map((role, index) => (
          <View style={styles.tableRow} key={index} wrap={false}>

            <View style={[styles.tableCol, { width: '25%' }]}>
              <Text style={styles.tableCell}>
                {!referenceRoles
                  ? parsePDFLabelToSupportWordBreaking(
                    role.label[langVersion]
                  )
                  : ''}
              </Text>
            </View>
            {referenceRoles &&
              <View style={[styles.tableCol, styles.equalColumn]}>
                <Text style={styles.tableCell} />
              </View>}
            <View style={[styles.tableCol, styles.equalColumn]}>
              <Text style={styles.tableCell} />
            </View>
            <View style={[styles.tableCol, styles.equalColumn]}>
              <Text style={styles.tableCell} />
            </View>
            <View style={[styles.tableCol, styles.equalColumn]}>
              <Text style={styles.tableCell} />
            </View>
            <View style={[styles.tableCol, styles.equalColumn]}>
              <Text style={styles.tableCell} />
            </View>
            <View style={[styles.tableCol, styles.equalColumn]}>
              <Text style={styles.tableCell} />
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}
