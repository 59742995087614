import { Trans, t } from '@lingui/macro'
import { getLabelFromTranslationData } from 'app/views/common/TranslationsCommon'
import { myI18n } from 'translation/I18nConnectedProvider'

const keyToLabel = {
  actualOutcomes: <Trans>FORM_OBJECTIVES_UPDATE_ACTUAL_OUTCOMES</Trans>,
  desiredOutcomeChanges: (
    <Trans>FORM_OBJECTIVES_UPDATED_DESIRED_OUTCOME_CHANGES</Trans>
  ),
  identifiedNeedChanges: (
    <Trans>FORM_OBJECTIVES_UPDATED_IDENTIFIED_NEEDS_CHANGES</Trans>
  )
}

const FormObjectivesUpdateErrorList = ({ vKey, item }) => {
  if (!item || !item[vKey]) return null

  return (
    <li>
      <b>{keyToLabel[vKey]}</b>: {item[vKey]}
    </li>
  )
}

export default function FormObjectivesUpdateError (
  error,
  { item, langVersion, value }
) {
  if (!error) {
    return null
  }
  return (
    <div>
      {error && (
        <li>
          {getLabelFromTranslationData({
            langVersion,
            data: item.title
          }) || myI18n?._(t`FORM_ELEMENT_OBJECTIVES_UPDATE`)}
          <ul>
            {error.map((obj, index) => {
              if (!obj) return null

              return (
                <li>
                  <u>{myI18n._(value[index].objective)}</u>
                  <ul>
                    <FormObjectivesUpdateErrorList
                      item={obj.objectivesUpdate}
                      vKey='actualOutcomes'
                    />
                    <FormObjectivesUpdateErrorList
                      item={obj.objectivesUpdate}
                      vKey='desiredOutcomeChanges'
                    />
                    <FormObjectivesUpdateErrorList
                      item={obj.objectivesUpdate}
                      vKey='identifiedNeedChanges'
                    />
                  </ul>
                </li>
              )
            })}
          </ul>
        </li>
      )}
    </div>
  )
}
