import { Trans } from '@lingui/macro'
import { Grid, Icon, InputAdornment, TextField } from '@material-ui/core'
import {
  PhoneCountryCodeFormat,
  PhoneFormat,
  PhoneFormatWithExtension,
  bilingualNumberFormat,
  getCurrentWords
} from 'app/views/common/Formats'
import {
  endEditingField,
  startEditingField,
  unlockFieldWithoutChanges,
  updateLockedFieldValue
} from 'app/views/forms/multiuser/grpcMultiuserEdit'
import { countHelperText } from 'app/views/page-layouts/FormElement'
import { FastField, useFormikContext } from 'formik'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { checkShouldUpdateForMultiuserField } from '../../common/Common'
import { MUEditedByLabel } from './MUEditedByLabel'
import { MUPreviouslySavedValuesPanel } from './MUPreviouslySavedValuesPanel'
import { multiuserColoredInputClasses } from './muStyles'

/**
 * Multiuser variant of Material UI TextField. Supports the same props as regular TextField with additional multiuser functionalities.
 * @category Multiuser
 * @component
 * @extends FormTextField
 * @extends FormNumericInput
 * @returns {JSX.Element}
 * @property {object} muBag Object containing multiuser data needef for making the queries.
 * @property {object} muBag.token Current multiuser session token.
 * @property {object} muBag.formId Id of a realm the component is used in.
 * @property {string} id Id of a field. Used for Formik and multiuser functionalities.
 * @property {boolean} [displayFieldHistoryIcon] If true, above the field the icon will be renderd which opens up a multiuser field history panel.
 * @property {boolean} [useMultiuser] If true, multiuser functionalities will be active in the component.
 */
const MUTextField = ({ muBag, ...props }) => {
  const { values, setFieldValue } = useFormikContext()
  const { muInfo = {}, muUsers = {} } = values
  let fieldValue = _.get(values, props.id)
  if (!fieldValue && fieldValue !== 0) {
    fieldValue = ''
  }
  const muState = muInfo[props.id]
  let userColor, userName, isEdited, muUser
  if (muState) {
    const { user, locked } = muState
    if (locked) {
      isEdited = true
      muUser = user
      userColor = muUsers[user]?.color
      userName = muUsers[user]?.name
    }
  }

  return (
    <MUTextFieldMemoized
      muUser={muUser}
      lockId={isEdited && muInfo.lockId}
      fieldValue={fieldValue}
      setFieldValue={setFieldValue}
      isEdited={isEdited}
      userColor={userColor}
      userName={userName}
      {...muBag}
      {...props}
    />
  )
}

const MUTextFieldMemoized = React.memo(({ ...props }) => {
  let {
    id,
    actionIconButton,
    actionIconButtona,
    elementType,
    multiuserEventOnChange = false,
    displayFieldHistoryIcon = false,
    showErrorWithoutTouch = false,
    useMultiuser,
    maxWords,
    wordCount,
    required,
    limit,
    maxInput,
    minInput,
    type = 'text',
    isPhone,
    isCountryCode,
    allowPhoneExtension,
    currency,
    langVersion,
    formId,
    token,
    userId,
    lockId,
    fieldValue,
    setFieldValue,
    isEdited,
    userColor,
    userName,
    muUser,
    showHelperText = true
  } = props
  const muBag = { formId, token, userId }
  const user = useSelector(state => state.user)
  const [inputValue, setInputValue] = useState(fieldValue)
  const [defaultZeroRemoved, setDefaultZeroRemoved] = useState(false)
  const [timer, setTimer] = useState(null)
  const [startEditingValue, setStartEditingValue] = useState(null)
  const settings = useSelector(state => state.layout.settings)
  const theme = settings.themes[settings.activeTheme]
  const successColor = theme?.palette.success.main
  const errorColor = theme?.palette.error.main

  const NumberFormatWithMinAndMax = useCallback(
    props => {
      const { inputRef, onChange, id, value = '', ...other } = props
      let formattedValue = ''
      // noinspection JSIncompatibleTypesComparison
      if (value || value === 0) {
        const num = String(value).replaceAll(',', '')
        formattedValue = parseFloat(Number(num).toFixed(2))
      }
      let separator = ','
      if (langVersion !== 'en' && currency) {
        separator = ' '
      }
      return (
        <NumberFormat
          {...other}
          isAllowed={({ floatValue }) => {
            if (!floatValue && floatValue !== 0) {
              return true
            }
            if (maxInput) {
              return floatValue <= Number(maxInput)
            }
            // else if (minInput && !maxInput) {
            //   return floatValue >= Number(minInput)
            // } else if (maxInput && minInput) {
            //   return (
            //     floatValue <= Number(maxInput) && floatValue >= Number(minInput)
            //   )
            // }
            return true
          }}
          value={typeof props.value === 'object' ? '' : formattedValue}
          defaultValue=''
          thousandSeparator={separator}
          isNumericString={false}
          getInputRef={inputRef}
          allowNegative={false}
          suffix={langVersion !== 'en' && currency ? ' $' : ''}
          prefix={langVersion === 'en' && currency ? '$' : ''}
          allowLeadingZeros={false}
          onValueChange={values => {
            onChange({
              target: {
                name: props.name,
                value: values.value
              }
            })
          }}
        />
      )
    },
    [maxInput, minInput, currency, langVersion]
  )

  const baseProps =
    type === 'number'
      ? {
          inputComponent: NumberFormatWithMinAndMax
        }
      : {}
  if (isPhone) {
    baseProps.inputComponent = allowPhoneExtension
      ? PhoneFormatWithExtension
      : PhoneFormat
  }
  if (isCountryCode) {
    baseProps.inputComponent = PhoneCountryCodeFormat
    limit = 5
  }

  useEffect(() => {
    setInputValue(fieldValue)
  }, [fieldValue])

  let helperArray = []
  const stringValue = inputValue || ''
  if (wordCount || maxWords) {
    helperArray.push(
      <span key='wordsCount'>
        {getCurrentWords(stringValue, maxWords)} <Trans>words</Trans>
        {'. '}
      </span>
    )
  }
  if (Number(limit) && !isCountryCode) {
    helperArray.push(
      <span key='limit'>
        {countHelperText(stringValue, Number(limit))}
        {'. '}
      </span>
    )
  }
  if (type === 'number' && !isPhone && !isCountryCode) {
    if (required) {
      helperArray = [
        <Trans key={1}>Required</Trans>,
        '. ',
        <Trans key={2}>Only numerical values are permitted in this field</Trans>
      ]
    } else {
      helperArray = [
        <Trans key={1}>Only numerical values are permitted in this field</Trans>
      ]
    }

    if (minInput) {
      helperArray.push(
        '. ',
        <Trans>
          Minimum value is: {bilingualNumberFormat(minInput, langVersion)}
        </Trans>
      )
    }
    if (maxInput) {
      helperArray.push(
        '. ',
        <Trans>
          Maximum value is: {bilingualNumberFormat(maxInput, langVersion)}
        </Trans>
      )
    }
    if (props.additionalHelperText) {
      helperArray.push('. ', props.additionalHelperText)
    }
  } else {
    if (required) {
      helperArray.push(
        <span key='required'>
          <Trans>Required</Trans>
          {'. '}
        </span>
      )
    }
  }

  const inputChanged = (e, validateOnKeystroke) => {
    let value = e.target.value
    if (value === inputValue) {
      return
    }
    let invalid = false
    if (maxWords) {
      const words = getCurrentWords(e.target.value)
      if (words > maxWords) {
        invalid = true
      } else if (words === maxWords) {
        value = value.trim()
      }
    }
    if (!invalid) {
      setInputValue(value)
    } else {
      setInputValue(inputValue)
    }
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      setFieldValue(id, value, validateOnKeystroke)
      if (useMultiuser) {
        updateLockedFieldValue({
          lockId,
          fieldId: id,
          fieldValue: value,
          ...muBag
        })
      }
    }, 700)
    setTimer(newTimer)
  }

  let marginBottom = 0
  const style = props.style || { marginTop: 5 }
  if (isCountryCode) {
    style.width = 65
  }
  if (style && style.marginBottom) {
    marginBottom = style.marginBottom || style.margin || 0
    if (isEdited) {
      delete style.marginBottom
    }
    style.marginBottom = isEdited ? 0 : marginBottom
  }

  const classes = multiuserColoredInputClasses(userColor)

  const component = (
    <Grid container direction='column' style={props.style || {}}>
      <FastField
        name={id}
        {...muBag}
        lockId={lockId}
        isEdited={isEdited}
        class={classes.root}
        inputValue={inputValue}
        disabled={Boolean(isEdited && muUser !== user.userId) || props.disabled}
        shouldUpdate={(nextProps, currentProps) => {
          return (
            checkShouldUpdateForMultiuserField(nextProps, currentProps) ||
            nextProps.inputValue !== currentProps.inputValue ||
            nextProps.class !== currentProps.class ||
            nextProps.name !== currentProps.name
          )
        }}
      >
        {({ field, meta, form }) => {
          const displayError =
            (meta.touched || showErrorWithoutTouch) && meta.error
          let inputError = false
          if (maxInput && inputValue) {
            if (+maxInput < +inputValue) {
              inputError = true
            }
          }
          if (minInput && inputValue) {
            if (+minInput > +inputValue) {
              inputError = true
            }
          }
          if (limit) {
            if (+limit < stringValue.length) {
              inputError = true
            }
          }
          let valueToShow = inputValue
          if (!valueToShow && valueToShow !== 0 && valueToShow !== '0') {
            valueToShow = props.defaultValue || ''
          }
          const showError =
            (meta.error && (meta.touched || showErrorWithoutTouch)) ||
            inputError
          const disabled =
            Boolean(isEdited && muUser !== user.userId) || props.disabled

          return (
            <TextField
              name={id}
              value={valueToShow}
              onChange={e => {
                if (props.onChange) {
                  props.onChange(e)
                } else {
                  inputChanged(e, Boolean(meta.touched))
                }
              }}
              error={showError}
              InputProps={{
                ...baseProps,
                endAdornment: required && !disabled && (meta.touched || valueToShow)
                  ? (
                    <InputAdornment position='end'>
                      {!showError
                        ? (
                          <Icon
                            style={{
                              color: successColor,
                              height: 'unset',
                              width: 'unset',
                              paddingRight: props.select && 15
                            }}
                          >
                            done
                          </Icon>
                          )
                        : (
                          <Icon
                            style={{
                              color: errorColor,
                              height: 'unset',
                              width: 'unset',
                              paddingRight: props.select && 15
                            }}
                          >
                            close
                          </Icon>
                          )}
                    </InputAdornment>
                    )
                  : (
                      ''
                    )
              }}
              inputProps={{
                maxLength: limit && Number(limit)
              }}
              select={props.select}
              children={props.children}
              className={classes.root}
              rows={props.rows}
              minRows={props.minRows}
              maxRows={props.maxRows}
              multiline={props.multiline}
              label={props.label}
              variant={props.variant || 'outlined'}
              fullWidth
              helperText={
                displayError ? meta.error : showHelperText ? (props.helperText || helperArray) : ''
              }
              style={!displayFieldHistoryIcon ? { wordBreak: 'break-word', ...style } : { wordBreak: 'break-word' }}
              disabled={disabled}
              type='text'
              onFocus={e => {
                if (!startEditingValue) {
                  setStartEditingValue(inputValue)
                }
                if (useMultiuser) {
                  startEditingField({
                    ...muBag,
                    fieldId: id
                  })
                }
                if (
                  type === 'number' &&
                  (valueToShow === 0 || valueToShow === '0')
                ) {
                  setDefaultZeroRemoved(true)
                  setInputValue('')
                }
              }}
              onBlur={e => {
                form.setFieldTouched(id)
                clearTimeout(timer)
                setFieldValue(id, inputValue)

                const v = e.target.value
                if (useMultiuser) {
                  const sameValue =
                    type === 'number'
                      ? Number(startEditingValue) ===
                        Number(String(v).replace(/,|\$/g, ''))
                      : startEditingValue === v
                  if (sameValue || multiuserEventOnChange) {
                    unlockFieldWithoutChanges({
                      lockId,
                      fieldId: id,
                      ...muBag
                    })
                  } else {
                    endEditingField({
                      ...muBag,
                      fieldId: id,
                      fieldValue:
                        type === 'number' && v
                          ? Number(String(v).replace(/,|\$/g, ''))
                          : v,
                      lockId
                    })
                  }
                }
                if (!meta.touched) {
                  form.setFieldTouched(id)
                }
                // field.onBlur(e)
                setStartEditingValue(null)
                if (type === 'number' && !inputValue && defaultZeroRemoved) {
                  setDefaultZeroRemoved(false)
                  setInputValue('0')
                } else if (defaultZeroRemoved) {
                  setDefaultZeroRemoved(false)
                }
                e.stopPropagation()
                e.preventDefault()
              }}
            />
          )
        }}
      </FastField>
      {useMultiuser && (
        <div style={{ marginBottom }}>
          <MUEditedByLabel color={userColor} userName={userName} />
        </div>
      )}
    </Grid>
  )

  const renderIcon =
    (displayFieldHistoryIcon && useMultiuser) ||
    actionIconButton ||
    actionIconButtona
  if (renderIcon) {
    return (
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
        style={style}
      >
        <Grid item xs>
          {component}
        </Grid>
        {displayFieldHistoryIcon && useMultiuser && (
          <Grid item style={{ paddingTop: 10 }}>
            <MUPreviouslySavedValuesPanel
              disabled={props.disabled}
              muBag={muBag}
              fieldId={id}
              type={elementType}
              useMultiuser={useMultiuser}
            />
          </Grid>
        )}
        <Grid style={{ paddingBottom: 20, alignSelf: 'center' }} item>
          {actionIconButton}
        </Grid>
        <Grid style={{ paddingBottom: 23, alignSelf: 'center' }} item>
          {actionIconButtona}
        </Grid>
      </Grid>
    )
  } else {
    return component
  }
})

export default MUTextField
